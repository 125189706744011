<script>
import tnVue from "@/mixins/tn.vue";

export default {
  mixins: [tnVue],

  data: () => ({
    dialog: true,
    ln: "support_welcome",
  }),

  watch: {
    dialog(v) {
      if (!v) setTimeout(() => this.$emit("close"), 100);
    },
  },
};
</script>

<template>
  <v-dialog
    v-model="dialog"
    max-width="800"
    content-class="custom-dialog"
  >
    <div
      @click="dialog = false"
      v-ripple
      class="btn-close"
    >
      <v-icon color="#676B92">mdi-close</v-icon>
    </div>

    <div class="_modal-content">
      <img
        src="@/assets/img/logo-inline.svg"
        alt=""
        width="300px"
        height="50px"
        class="mb-2"
      />

      <p class="_description">{{ tn("description") }}</p>

      <h1 class="mt-5">{{ tn("title") }}</h1>

      <div class="contacts mt-4">
        <div class="d-flex align-center">
          <img
            src="@/assets/icons/call.svg"
            alt=""
          />
          <div class="px-3" />
          <a
            class="info-text"
            href="tel:712036766"
          >
            (71) 203
            <span class="primary--text"> OSON </span>
            (6766)
          </a>
        </div>

        <div class="d-flex align-center">
          <img
            src="@/assets/icons/call.svg"
            alt=""
          />
          <div class="px-3" />
          <a
            class="info-text"
            href="tel:944846766"
          >
            (94) 484 67 66 - Faxriddin
          </a>
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<style lang="scss" scoped>
::v-deep(.custom-dialog) {
  position: relative;
  border-radius: 20px;
  max-width: 800px;
  background: #ffffff;
  padding: 30px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.15);

  .btn-close {
    position: absolute;
    top: 16px;
    right: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 40px;
    background-color: white;
    border-radius: 8px;
    cursor: pointer;
  }
}

._modal-content {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    max-width: 100%;
    height: auto;
  }

  ._description {
    font-size: 22px;
    font-weight: 500;
    color: #374151;
    margin-top: 15px;
    line-height: 1.5;
  }

  h1 {
    font-size: 26px;
    font-weight: bold;
    color: #1f2937;
    margin-top: 20px;
  }

  .contacts {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-top: 25px;
    width: 100%;

    .contact-item {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      background: #f9fafb;
      padding: 12px 20px;
      border-radius: 12px;
      transition: all 0.3s ease-in-out;

      &:hover {
        background: #eef2ff;
        transform: scale(1.03);
      }

      img {
        width: 24px;
        height: 24px;
      }

      .info-text {
        font-size: 18px;
        font-weight: 500;
        color: #1f2937;
        text-decoration: none;
        transition: color 0.3s ease-in-out;

        &:hover {
          color: #2563eb;
          text-decoration: underline;
        }

        span {
          color: #2563eb;
          font-weight: bold;
        }
      }
    }
  }
}
</style>
