import Vue from "vue";

let timeData = {
  server: null,
  local: null,
};

export const setRealTime = (e) => {
  timeData = e;
};

export const getRealTime = (dd, mm, hh) => {
  if (timeData.server) {
    const diff = new Date().getTime() - timeData.local;
    const _date = new Date(diff + timeData.server);
    if (dd) _date.setDate(_date.getDate() + dd);
    if (mm) _date.setMonth(_date.getMonth() + mm);
    if (hh) _date.setHours(_date.getHours() + hh);
    return _date;
  }
};

const getDiffMilliseconds = (expDateString, allowMinus) => {
  if (!expDateString) return 0;

  const serverTime = getRealTime();
  const expDate = new Date(expDateString);
  const diff = expDate?.getTime?.() - serverTime.getTime();
  if (allowMinus) return diff;
  return diff > 0 ? diff : 0;
};

Vue.prototype.$getRealTime = getRealTime;
Vue.prototype.$getDiffMilliseconds = getDiffMilliseconds;
