<script>
import debounce from "debounce";
import tnVue from "@/mixins/tn.vue";
import { mapActions, mapGetters } from "vuex";

import TableCog from "@/components/table-cog.vue";
import TableResizeVue from "@/mixins/table-resize.vue";
import TablePagination from "@/mixins/table-pagination.vue";
import TableNavFunctionsVue from "@/mixins/table-nav-functions.vue";

export default {
  components: { TableCog },

  data: (vm) => ({
    ln: "basket.history",
    uniqKey: "basket-history",
    options: vm.$getSortLocal({ key: "basket-history" }),
  }),

  mixins: [tnVue, TableResizeVue, TablePagination, TableNavFunctionsVue],

  computed: {
    ...mapGetters("basket", { basketLineItem: "GET_LINE_ITEM_BY_GUID" }),
    ...mapGetters("references", { basketStatus: "GET_BASKET_STATUS" }),
    ...mapGetters("basket-history", {
      entries: "GET",
      entriesCount: "GET_COUNT",
      entriesTotals: "GET_TOTALS",
    }),

    size: (vm) => vm.$store.getters.GET_SIZE,
    tableHeight: (vm) => vm.size.height - 158,
    basketLineItemGuid: (vm) => vm.$route.params.basketLineItemGuid,
    priceListLineItemGuid: (vm) => vm.$route.params.priceListLineItemGuid,

    tableHeaders: (vm) =>
      [
        {
          text: "№",
          value: "_number",
          width: 10,
          sortable: false,
          class: "disable-resize",
        },
        {
          text: vm.tn("table.provider"),
          value: "providerName",
          _required: true,
        },
        {
          text: vm.tn("table.price_type"),
          value: "priceTypeName",
          width: 10,
        },
        {
          text: vm.tn("table.status"),
          value: "status",
          width: 10,
          _filter: (v) => vm.basketStatus.find((x) => x.id == v)?.name,
        },
        {
          text: vm.tn("table.time_started"),
          value: "timeStarted",
          width: 10,
          _filter: (v) => vm.$dateTime(v),
        },
        {
          text: vm.tn("table.time_ended"),
          value: "timeEnded",
          width: 10,
          _filter: (v) => vm.$dateTime(v),
        },
        {
          text: vm.tn("table.items_count"),
          value: "basketQuantity",
          width: 10,
          _filter: (v) => vm.$sum(v),
        },
        {
          text: vm.tn("table.total_amount"),
          value: "totalAmount",
          width: 10,
          _filter: (v) => vm.$sum(v),
        },
      ].map((x) => {
        if (vm.tdSizes[x.value]) x.width = vm.tdSizes[x.value];
        return x;
      }),
  },

  async created() {
    await this.getByGuidApi({ guid: this.basketLineItemGuid });
    if (!this.basketLineItem?.id) this.$router.push("/basket");
  },

  watch: {
    searchText() {
      this.onSearch();
    },
  },

  methods: {
    ...mapActions("basket", { getByGuidApi: "GET_LINE_ITEM_BY_GUID_API" }),
    ...mapActions("basket-history", ["GET_API"]),

    async getEntries() {
      let loaders = { ...this.loaders };
      const loadKey = this.$uuid();
      loaders[loadKey] = true;
      this.loaders = loaders;

      this.$setSortLocal({
        key: "basket-history",
        options: this.options,
      });

      const params = {
        options: {
          limit: this.options.itemsPerPage,
          offset: this.options.itemsPerPage * (this.options.page - 1),
          sortDirection: this.$getSort(this.options.sortDesc, true),
          sortBy: this.$getSort(this.options.sortBy),
          sortOrders: this.$getSortOrders(this.options),
          priceListLineItemGuid: this.priceListLineItemGuid,
        },
      };
      if (!params.options.sortBy) delete params.options.sortDirection;

      if (this.searchText) params.options.search = this.searchText;

      await this.GET_API(params);

      if (document.activeElement?.id != this.ln) {
        this.setFocusToItem();
      } else {
        this.selected = {};
      }

      loaders = { ...this.loaders };
      delete loaders[loadKey];
      this.loaders = loaders;
    },

    onSearch: debounce(function () {
      this.options.page = 1;
      this.$nextTick(() => this.getEntries());
    }, 200),

    onSelect({ item }) {
      this.selected = { ...item };
      this.$setInputFocus(item._id, this.uniqKey);
    },

    onExit() {
      if (!this.$router.back()) this.$router.replace("/basket");
    },

    onKeyup(e) {
      if (e.keyCode == 113) {
        this.$refs.searchBox?.focus();
        this.$nextTick(() => {
          const input = this.$refs.searchBox?.$el?.querySelector("input");
          input?.select();
        });
      }
    },

    onKeyupDown() {
      if (this.isLoading) return;
      this.setFocusToItem();
    },
  },
};
</script>

<template>
  <v-container fluid>
    <div class="d-flex align-center">
      <v-btn
        min-width="40"
        width="40"
        outlined
        color="primary"
        replace
        @click="onExit"
        depressed
        class="mr-3"
      >
        <v-icon> mdi-close </v-icon>
      </v-btn>

      <div>
        <h3>
          {{ basketLineItem.productName }}
          ({{ basketLineItem.manufacturerName }})
        </h3>
        <small>
          <b> {{ tn("header.shelf_life") }}: </b>
          {{ basketLineItem.shelfLife | date }};
          <b> {{ tn("header.price_type") }}: </b>
          {{ basketLineItem.priceTypeName }};
          <b> {{ tn("header.amount") }}: </b>
          {{ basketLineItem.amount | sum }};
          <b> {{ tn("header.quantity") }}: </b>
          {{ basketLineItem.basketQuantity | sum }};
          <b> {{ tn("header.total_amount") }}: </b>
          {{ basketLineItem.totalAmount | sum }}
        </small>
      </div>

      <v-spacer />

      <v-btn
        color="primary"
        min-width="40"
        width="40"
        height="40"
        min-height="40"
        outlined
        @click="getEntries"
        :disabled="isLoading"
        :loading="isLoading"
        :title="tn('refresh_btn')"
        class="mr-2"
      >
        <v-icon> mdi-sync </v-icon>
      </v-btn>

      <table-cog
        :headersData="tableHeaders"
        @apply="(v) => (filteredHeaders = $compareArray(tableHeaders, v, 'value'))"
        :cacheKey="'history-items-table-cog'"
        class="mr-2"
      />

      <v-text-field
        :id="ln"
        ref="searchBox"
        v-model="searchText"
        outlined
        hide-details
        dense
        append-icon="mdi-magnify"
        clearable
        style="max-width: 300px; width: 100%"
        :label="tn('search')"
        @click:append="onSearch"
        @keyup.enter="onKeyupDown({ enter: true })"
        @keyup.down="onKeyupDown"
        @keyup.up="onKeyupDown"
      />
    </div>

    <div class="mt-1" />

    <v-data-table
      v-if="filteredHeaders.length && tableHeight > 0"
      :headers="filteredHeaders"
      dense
      fixed-header
      disable-pagination
      hide-default-footer
      class="fixed-left fixed-right"
      :items="entries"
      :mobile-breakpoint="0"
      :loading="isLoading"
      :options.sync="options"
      :server-items-length="entriesCount"
      :height="tableHeight"
      v-resize-table="{ tableHeaders: filteredHeaders, callback: onUpdateTdSizes }"
    >
      <template v-slot:item="{ item, headers, index }">
        <tr
          class="cursor-pointer"
          @click.stop="onSelect({ item })"
          :class="selected.guid == item.guid && 'tr-active'"
        >
          <template v-for="head in headers">
            <td
              v-if="head.value == '_number'"
              :key="head.value"
              class="px-0 text-center"
            >
              <span
                class="text-nowrap"
                v-text="$sum((options.page - 1) * options.itemsPerPage + (index + 1))"
              />
              <table-focus-input
                :ref="item.guid"
                :tabindex="item._id"
                :item="item"
                :uniqKey="uniqKey"
                @upKey="onUpKey({ item })"
                @downKey="onDownKey({ item })"
                @pageUp="onPageUp({ item })"
                @pageDown="onPageDown({ item })"
              />
            </td>

            <td
              v-else-if="head._filter"
              :key="head.value + 1"
              :style="{ ...head._getStyle?.(item[head.value], item), width: head.width }"
            >
              <div
                class="text-nowrap"
                v-html="head._filter(item[head.value], item)"
                :style="{
                  textAlign: head._align,
                  minWidth: head.minWidth,
                  color: head._getColor?.(item[head.value], item),
                }"
              />
            </td>

            <td
              v-else
              :key="head.value + 2"
              :style="{ width: tdSizes[head.value] || head.width, maxWidth: tdSizes[head.value] || head.width }"
            >
              <div
                :style="{ minWidth: head.minWidth }"
                v-text="item[head.value]"
              />
            </td>
          </template>
        </tr>
      </template>

      <template
        v-if="entriesCount > 1"
        #body.append="bodyProps"
      >
        <tr class="sticky-table-footer">
          <td
            v-for="(header, i) in bodyProps.headers"
            :key="i"
          >
            <div
              v-if="header.value == '_number'"
              class="text-nowrap text-center"
              v-text="$sum(entriesCount)"
            />
            <div
              v-if="header.value"
              class="text-nowrap text-right"
              v-text="$sum(entriesTotals[header.value], true)"
            />
          </td>
        </tr>
      </template>

      <template #footer>
        <TablePaginationButtons
          :loading="isLoading"
          :options="options"
          :lastPage="lastPage"
          :perPageInput="perPageInput"
          :pageInput="pageInput"
          :entriesCount="entriesCount"
          :onLastPage="onLastPage"
          :onChangePerPageInput="onChangePerPageInput"
          :onChangePageInput="onChangePageInput"
        />
      </template>
    </v-data-table>
  </v-container>
</template>
