<script>
import { mapActions, mapGetters } from "vuex";
export default {
  props: {
    item: Object,
    tabindex: Number,
    itemsLength: Number,
    tn: Function,
    uniKey: {
      type: String,
      required: true,
    },
    disabled: Boolean,
  },

  data: () => ({
    count: null,
  }),

  watch: {
    ["item.basketQuantity"](v) {
      this.count = v;
    },
  },

  created() {
    if (this.item.basketQuantity > 0) this.count = this.item.basketQuantity;
  },

  computed: {
    ...mapGetters("price-list-items", ["GET_PENDING_ITEM", "GET_SENDING_ITEM"]),
    pendingItem: (vm) => vm.GET_PENDING_ITEM(vm.item, vm.uniKey),
    sendingItem: (vm) => vm.GET_SENDING_ITEM(vm.item, vm.uniKey),
  },

  methods: {
    ...mapActions("price-list-items", ["SET_ITEM_QTY"]),

    onKeyUp(e) {
      if (e.code == "PageUp") this.$emit("pageUp");
      else if (e.code == "PageDown") this.$emit("pageDown");
      else if (e.code === "NumpadSubtract") {
        if (!this.$number(this.count)) this.count = null;
        e.preventDefault();
      }
    },

    onBlur() {
      if (this.item.basketQuantity == this.count) return;

      const params = {
        priceListLineItemGuid: this.item.priceListLineItemGuid,
        priceListLineItemPriceGuid: this.item.priceListLineItemPriceGuid,
        quantity: this.$number(this.count),
        id: this.item.id,
        isAddToRefusedList: true,
      };

      this.SET_ITEM_QTY(params);
    },

    onEnter() {
      if (this.tabindex == this.itemsLength) {
        this.onBlur();
      }

      this.$nextTick(() => this.$emit("downKey"));
    },

    onInput(e) {
      if (this.disabled) e.target.value = this.count;
      else this.count = e.target.value;
    },
  },
};
</script>

<template>
  <div class="tb-cart-input">
    <input
      @keydown.up="$emit('upKey')"
      @keydown.down="$emit('downKey')"
      @keyup.enter="onEnter"
      @keyup="onKeyUp"
      :tabindex="tabindex"
      v-inputmask="$masks.numberMax5"
      :value="count"
      @input="onInput"
      @blur="onBlur"
      :class="uniKey"
    />
    <v-progress-circular
      v-if="pendingItem || sendingItem"
      width="1.5"
      indeterminate
      :color="sendingItem ? 'primary' : null"
      size="15"
      class="loader"
    />

    <div class="item-delete-wrap">
      <v-btn
        :title="tn('table.actions.delete')"
        @click="$emit('delete')"
        icon
        :disabled="!!pendingItem"
        v-if="item.basketLineItemGuid"
        x-small
      >
        <v-icon>mdi-delete</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.tb-cart-input {
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  overflow: hidden;

  input {
    outline: none;
    height: 100%;
    width: 100%;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 20px;
    padding-right: 20px;
    font-weight: 600;

    background-color: #f2f8ff;

    &:focus {
      box-shadow: 0 0 3px inset #1976d2;
    }
  }

  .loader {
    position: absolute;
    left: 3px;
  }

  .item-delete-wrap {
    position: absolute;
    right: 0px;
  }
}
</style>
