import Vue from "vue";
import VueRouter from "vue-router";
import RouteGuard from "./utils/route-guard";
import RouteGuardIsOrderPermission from "./utils/route-guard-is-order-permission";

import Auth from "./views/auth/auth.vue";
import Register from "./views/auth/register.vue";

import Home from "./views/home/home.vue";
import Statistic from "./views/home/statistic/statistic.vue";

import Selection from "./views/selection/selection.vue";
import SelectionTop from "./views/selection-top/selection-top.vue";
import SelectionAuto from "./views/selection-auto/selection-auto.vue";
import SelectionAutoImport from "./views/selection-auto/selection-auto-import.vue";
import SelectionAutoJoin from "./views/selection-auto/selection-auto-join.vue";
import SelectionAutoResult from "./views/selection-auto/selection-auto-result/selection-auto-result.vue";
import SelectionAutoResultByProduct from "./views/selection-auto/selection-auto-result-by-product/selection-auto-result-by-product.vue";

import PriceList from "./views/price-list/price-list.vue";
import PriceListShow from "./views/price-list/price-list-show.vue";
import PriceListCompare from "./views/price-list/price-list-compare.vue";

import Basket from "./views/basket/basket.vue";
import BasketMove from "./views/basket/basket-move.vue";
import BasketRefused from "./views/basket/basket-refused/basket-refused.vue";
import BasketHistory from "./views/basket/basket-history.vue";
import BasketFresh from "./views/basket/basket-fresh/basket-fresh.vue";
import BasketPriceListProviderResultImport from "./views/basket/basket-price-list-provider-result/basket-price-list-provider-result-import.vue";
import BasketPriceListProviderResultJoin from "./views/basket/basket-price-list-provider-result/basket-price-list-provider-result-join.vue";

import PriceListViewerOrder from "./views/price-list-viewer-order/price-list-viewer-order.vue";
import PriceListViewerOrderView from "./views/price-list-viewer-order/price-list-viewer-order-view/price-list-viewer-order-view.vue";

import PharmacyRequirementsImport from "./views/pharmacy-requirements/pharmacy-requirements-import.vue";
import PharmacyRequirementsJoin from "./views/pharmacy-requirements/pharmacy-requirements-join.vue";
import PharmacyRequirementsProductsLink from "./views/pharmacy-requirements/products-links/products-links.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: Auth,
    beforeEnter: (to, from, next) => {
      const token = Vue.prototype.$cookies.get("osonprice_token");
      next(token && !to.query.logout ? "/home" : true);
    },
  },
  {
    path: "/register",
    component: Register,
  },
  {
    path: "/home",
    component: Home,
    children: [
      {
        path: "/",
        component: Statistic,
        beforeEnter: RouteGuard,
        meta: { auth: true },
      },
      {
        path: "/edit-profile",
        component: lazyView("account/edit-profile"),
        beforeEnter: RouteGuard,
        meta: { auth: true },
      },
      // {
      //   path: "/edit-phone",
      //   component: lazyView("account/edit-phone"),
      //   beforeEnter: RouteGuard,
      //   meta: { auth: true },
      // },
      {
        path: "/edit-inn",
        component: lazyView("account/edit-inn"),
        name: "inn",
        beforeEnter: RouteGuard,
        meta: { auth: true },
      },
      {
        path: "/telegram-bot-connect",
        component: lazyView("account/telegram-bot-connect"),
        beforeEnter: RouteGuard,
        meta: { auth: true },
      },
      {
        path: "/selection",
        component: Selection,
        beforeEnter: RouteGuard,
        meta: { auth: true },
      },
      {
        path: "/selection/top/:type?",
        component: SelectionTop,
        beforeEnter: RouteGuard,
        meta: { auth: true },
      },
      {
        path: "/selection/auto",
        component: SelectionAuto,
        beforeEnter: RouteGuard,
        meta: { auth: true },
      },
      {
        path: "/selection/auto/import",
        component: SelectionAutoImport,
        beforeEnter: RouteGuard,
        meta: {
          auth: true,
          parent: "/selection/auto",
        },
      },
      {
        path: "/selection/auto/join/:guid",
        component: SelectionAutoJoin,
        beforeEnter: RouteGuard,
        meta: {
          auth: true,
          parent: "/selection/auto",
        },
      },
      {
        path: "/selection/auto/result/:resultGuid",
        component: SelectionAutoResult,
        beforeEnter: RouteGuard,
        meta: {
          auth: true,
          parent: "/selection/auto",
        },
      },
      {
        path: "/selection/auto/result/:resultGuid/product",
        component: SelectionAutoResultByProduct,
        beforeEnter: RouteGuard,
        meta: {
          auth: true,
          parent: "/selection/auto",
        },
      },
      {
        path: "/price-list",
        component: PriceList,
        beforeEnter: RouteGuard,
        meta: { auth: true },
      },
      {
        path: "/price-list/:guid",
        name: "price-list-show",
        component: PriceListShow,
        beforeEnter: RouteGuard,
        meta: { auth: true },
      },
      {
        path: "/price-list/compare/:guid",
        name: "price-list-compare",
        component: PriceListCompare,
        beforeEnter: RouteGuard,
        meta: { auth: true },
      },
      {
        path: "/basket",
        component: Basket,
        beforeEnter: RouteGuard,
        meta: { auth: true },
      },
      {
        path: "/basket/move/:basketLineItemGuid/:priceListLineItemGuid",
        component: BasketMove,
        beforeEnter: RouteGuard,
        meta: { auth: true },
      },
      {
        path: "/basket/refused-list",
        component: BasketRefused,
        beforeEnter: RouteGuard,
        meta: { auth: true },
      },
      {
        path: "/basket/history/:basketLineItemGuid/:priceListLineItemGuid",
        component: BasketHistory,
        beforeEnter: RouteGuard,
        meta: { auth: true },
      },
      {
        path: "/basket/fresh/:basketGuid/:activeTab?",
        component: BasketFresh,
        beforeEnter: RouteGuard,
        meta: { auth: true },
      },
      // {
      //   path: "/favorite",
      //   component: lazyView("favorite/favorite"),
      //   beforeEnter: RouteGuard,
      //   meta: { auth: true },
      // },
      // {
      //   path: "/my-discount",
      //   component: lazyView("my-discount/my-discount"),
      //   beforeEnter: RouteGuard,
      //   meta: { auth: true },
      // },
      {
        path: "/price-list-viewer-order",
        component: PriceListViewerOrder,
        beforeEnter: RouteGuardIsOrderPermission,
        meta: { auth: true },
      },
      {
        path: "/price-list-viewer-order/:priceListViewerOrderId?",
        component: PriceListViewerOrderView,
        beforeEnter: RouteGuard,
        meta: { auth: true },
      },
      {
        path: "/history",
        component: lazyView("history/history"),
        beforeEnter: RouteGuard,
        meta: { auth: true },
      },
      {
        path: "/history/:guid",
        component: lazyView("history/history-items"),
        beforeEnter: RouteGuard,
        meta: { auth: true },
      },
      {
        path: "/history/:guid/basket-price-list-provider-result/import",
        component: BasketPriceListProviderResultImport,
        beforeEnter: RouteGuard,
        meta: {
          auth: true,
          parent: "/history/:guid",
        },
      },
      {
        path: "/history/:basketGuid/basket-price-list-provider-result/join/:sessionGuid",
        component: BasketPriceListProviderResultJoin,
        beforeEnter: RouteGuard,
        meta: {
          auth: true,
          parent: "/history/:guid",
        },
      },
      {
        path: "/pharmacy-requirements",
        component: lazyView("pharmacy-requirements/pharmacy-requirements"),
        beforeEnter: RouteGuard,
        meta: { auth: true },
      },
      {
        path: "/pharmacy-requirements/import",
        component: PharmacyRequirementsImport,
        beforeEnter: RouteGuard,
        meta: {
          auth: true,
          parent: "/pharmacy-requirements",
        },
      },
      {
        path: "/pharmacy-requirements/join/:guid",
        component: PharmacyRequirementsJoin,
        beforeEnter: RouteGuard,
        meta: {
          auth: true,
          parent: "/pharmacy-requirements",
        },
      },
      {
        path: "/pharmacy-requirements/products-link",
        component: PharmacyRequirementsProductsLink,
        beforeEnter: RouteGuard,
        meta: {
          auth: true,
          parent: "/pharmacy-requirements",
        },
      },
    ],
  },
  {
    path: "/price-list-provider/:guid",
    component: lazyView("price-list/price-list-provider-info"),
    beforeEnter: RouteGuard,
    meta: { auth: true },
  },
  {
    path: "*",
    redirect: "/",
  },
];

function lazyView(view) {
  return () => {
    return import(/* webpackChunkName: "lazy-view-[request]" */ `./views/${view}.vue`);
  };
}

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
