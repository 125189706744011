<script>
import LoadingDialog from "./components/loading-dialog.vue";
import * as debounce from "debounce";

import { mapGetters } from "vuex";
import AppUpdateNotification from "./components/app-update-notification/app-update-notification.vue";

export default {
  components: { LoadingDialog, AppUpdateNotification },

  data: () => ({
    isShowRoute: true,

    actualVersion: null,
  }),

  watch: {
    "$vuetify.theme.dark"(v) {
      localStorage.setItem("theme--dark", v);
    },

    "$i18n.locale"(v) {
      this.$store.dispatch("SET_INIT", true);
      localStorage.setItem("language", v);
      this.$nextTick(() => this.$store.dispatch("SET_INIT", false));
    },

    "$route.params"() {
      this.isShowRoute = false;
      this.$nextTick(() => (this.isShowRoute = true));
    },
  },

  computed: {
    ...mapGetters("auth", {
      user: "GET_USER",
    }),

    snackbar: (vm) => vm.$store.getters.GET_SNACKBAR,
    snackbar2: (vm) => vm.$store.getters.GET_SNACKBAR2,

    isInit: (vm) => vm.$store.getters.GET_INIT,
    isLoading: (vm) => vm.$store.getters.GET_LOADING,
    isLoading2: (vm) => vm.$store.getters.GET_LOADING2,
    size: (vm) => vm.$store.getters.GET_SIZE,

    isUserLoggedIn: (vm) => !!vm.user.serverTime,
  },

  mounted() {
    this.onResize();
  },

  methods: {
    onResize: debounce(function () {
      const size = {
        width: window.innerWidth,
        height: window.innerHeight,
      };

      if (size.width < 1264) {
        size.height = size.height - 35;
      }

      this.$store.dispatch("SET_SIZE", size);
    }, 500),

    onHardRefresh() {
      location.reload(true);
    },
  },
};
</script>

<template>
  <v-app v-resize="onResize">
    <v-snackbar
      :top="snackbar.top == undefined ? true : snackbar.top"
      :right="snackbar.right"
      :bottom="snackbar.bottom"
      v-model="snackbar.isShow"
      :color="snackbar.color"
      :timeout="8500"
      style="z-index: 20011"
    >
      <div v-html="snackbar.text" />
      <template slot="action">
        <v-btn
          depressed
          min-width="40"
          width="40"
          :title="$t('close')"
          dark
          color="transparent"
          @click="snackbar.isShow = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar
      right
      bottom
      v-model="snackbar2.isShow"
      color="warning"
      :timeout="10000"
      style="z-index: 20011"
    >
      <div v-html="snackbar2.text" />
      <template slot="action">
        <v-btn
          depressed
          min-width="40"
          width="40"
          :title="$t('close')"
          dark
          color="transparent"
          @click="snackbar2.isShow = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>

    <LoadingDialog :show="isInit || isLoading" />
    <LoadingDialog
      v-if="!isInit && !isLoading"
      :show="isLoading2"
      :hideOverlay="true"
    />

    <div
      v-if="actualVersion"
      class="text-center"
    >
      <div class="pb-3 pt-15">
        <v-icon
          size="60"
          color="primary"
          class="mb-5 icon-spinner"
        >
          mdi-autorenew
        </v-icon>

        <h4 v-html="$t('expired_version.content')" />
      </div>

      <v-btn
        color="primary"
        @click="onHardRefresh"
      >
        {{ $t("expired_version.refresh_btn") }}
      </v-btn>

      <div class="pt-5">
        <div>
          <small> {{ $t("expired_version.current_version") }}: {{ $version }} </small>
        </div>
        <div>
          <small> {{ $t("expired_version.actual_version") }}: {{ actualVersion }} </small>
        </div>

        <small
          class="warning--text"
          v-html="$t('expired_version.waring_text')"
        />
      </div>
    </div>

    <template v-else>
      <template v-if="isUserLoggedIn">
        <AppUpdateNotification @actualVersion="(v) => (actualVersion = v)" />
      </template>

      <router-view v-if="!isInit && isShowRoute && size.height > 0" />
    </template>
  </v-app>
</template>

<style lang="scss" scoped>
.icon-spinner {
  animation: spin-animation 1.5s 3;
  display: inline-block;
}

@keyframes spin-animation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(359deg);
  }
}
</style>
