<script>
import debounce from "debounce";
import tnVue from "@/mixins/tn.vue";
import { mapActions, mapGetters } from "vuex";
import TableCog from "@/components/table-cog.vue";
import TableFocusInput from "./table-focus-input.vue";
import TableResizeVue from "@/mixins/table-resize.vue";
import TablePagination from "@/mixins/table-pagination.vue";
import TableNavFunctionsVue from "@/mixins/table-nav-functions.vue";
import ConfirmDialog from "@/components/modals/confirm-dialog.vue";

export default {
  components: {
    TableCog,
    ConfirmDialog,
    TableFocusInput,
  },

  props: {
    sortBy: String,
    tableHeight: Number,
    ln: String,
    isFocusable: Boolean,
  },

  data: (vm) => ({
    uniqKey: "refused-list",
    options: vm.$getSortLocal({
      key: "refused-list",
      options: {
        page: vm.$number(localStorage.getItem(`doc-page-refused-list`)) || 1,
      },
    }),
    selectedRows: vm.$browserStore.getSession("refusedSelectedRows") || [],
    isShowDelete: false,
    isShowBasketsDelete: false,
  }),

  mixins: [tnVue, TablePagination, TableResizeVue, TableNavFunctionsVue],

  computed: {
    ...mapGetters("basket-refused", {
      entries: "GET",
      entriesCount: "GET_COUNT",
    }),

    tableHeaders: (vm) =>
      [
        {
          text: vm.tn("table.providerName"),
          value: "providerName",
          width: vm.$dxcs.priceListProviderName,
          _required: true,
        },
        {
          text: vm.tn("table.totalAmount"),
          value: "totalAmount",
          _filter: (v) => vm.$sum(v),
          _required: true,
          width: vm.$dxcs.totalAmount,
        },
        {
          text: vm.tn("table.date_and_time_of_formation"),
          value: "timeStarted",
          _filter: (v) => vm.$dateTime(v),
          width: "120px",
          _required: true,
        },
        {
          text: vm.tn("table.priceTypeName"),
          value: "priceTypeName",
          width: vm.$dxcs.priceTypeName,
          _required: true,
        },
        {
          text: vm.tn("table.itemsCount"),
          value: "itemsCount",
          _filter: (v) => vm.$sum(v),
          _required: true,
        },
      ].map((x) => {
        if (vm.tdSizes[x.value]) x.width = vm.tdSizes[x.value];
        return x;
      }),

    realHeaders: (vm) => [
      {
        width: 10,
        class: "disable-resize disable-tr-size",
        value: "data-table-select",
      },
      ...vm.filteredHeaders,
    ],

    actionsList: (vm) => [
      {
        name: vm.tn("actions.export_excel_btn"),
        action: (item) => vm.onExportExcel({ item }),
      },
      {
        name: vm.tn("actions.delete_btn"),
        action: (item) => vm.onDeleteShow({ item }),
        class: "error--text",
      },
    ],

    actionsListMulti: (vm) => [
      {
        name: vm.tn("actions.delete_btn"),
        action: () => {
          vm.selectedRows = vm.selectedRows.filter((x) => vm.entries.find((y) => y.guid == x.guid));
          vm.isShowBasketsDelete = true;
        },
        class: "error--text",
      },
    ],
  },

  created() {
    this.selectedRows = this.selectedRows.filter((row) => this.entries.some((entry) => entry.guid === row.guid));
    if (this.selectedRows.length) this.$browserStore.setSession("refusedSelectedRows", [...this.selectedRows]);
  },

  watch: {
    selectedRows(v = []) {
      this.$emit("selectedRows", [...v]);
      this.$browserStore.setSession("refusedSelectedRows", [...v]);
    },
  },

  methods: {
    ...mapActions("basket-refused", {
      getEntriesApi: "GET_API",
    }),

    ...mapActions("basket", {
      deleteApi: "REMOVE_API",
      deleteSelectedBaskets: "DELETE_SELECTED_BASKETS_API",
    }),

    async getEntries(payload) {
      if (payload?.reset) this.options.page = 1;

      let loaders = { ...this.loaders };
      const loadKey = this.$uuid();
      loaders[loadKey] = true;
      this.loaders = loaders;

      this.$setSortLocal({ key: "refused-list", options: this.options });

      const params = {
        options: {
          limit: this.options.itemsPerPage,
          offset: this.options.itemsPerPage * (this.options.page - 1),
          sortDirection: this.$getSort(this.options.sortDesc, true),
          sortBy: this.$getSort(this.options.sortBy),
          search: this.searchText,
        },
      };

      await this.getEntriesApi(params);

      if (!this.checkFocused()) this.setFocusToItem();
      else this.selected = {};

      loaders = { ...this.loaders };
      delete loaders[loadKey];
      this.loaders = loaders;
    },

    checkFocused() {
      return document.activeElement.className.includes(this.uniqKey);
    },

    onSearch: debounce(function () {
      this.getEntries({ reset: true });
    }, 200),

    onKeyup(e) {
      if (e.keyCode == 113) this.$refs.searchBox?.focus();
    },

    onClear() {
      this.searchText = null;
      this.$nextTick(() => this.getEntries());
    },

    onKeyUpEnter({ item }) {
      const isSelected = this.$refs.table?.isSelected(item);
      this.$refs.table?.select(item, !isSelected);
    },

    onSelect({ item }) {
      this.selected = { ...item };
      this.$setInputFocus(item._id, this.uniqKey);
    },

    async onExportExcel({ item }) {
      this.$store.dispatch("SET_LOADING", true);

      const url = `Basket/${item.guid}/Export`;
      await this.$axios.$postResFile(url);

      this.$store.dispatch("SET_LOADING", false);
    },

    onDeleteShow({ item }) {
      this.selected = { ...item };
      this.$nextTick(() => (this.isShowDelete = true));
    },

    async onDelete({ close, setLoading }) {
      setLoading(true);
      const params = { basketGuid: this.selected.guid };
      const status = await this.deleteApi(params);
      if (status) {
        close();
        this.selectedRows = this.selectedRows.filter((x) => x.guid != params.basketGuid);

        this.getEntries();
      }
      setLoading(false);
    },

    async onDeleteSelected({ close, setLoading }) {
      setLoading(true);
      const status = await this.deleteSelectedBaskets({
        basketGuids: this.selectedRows.map((x) => x.guid),
      });

      setLoading(false);
      if (status) {
        close();
        this.$emit("refresh");
        this.selectedRows = [];
      }
    },

    onClickRow(item, params) {
      if (item && params) {
        this.onSelect({ item });
        this.onKeyUpEnter({ item });
      }
    },
  },
};
</script>

<template>
  <div>
    <ConfirmDialog
      :text="
        tn('delete_alert', {
          0: `<br />${selected.providerName} (${selected.priceTypeName})`,
        })
      "
      @close="isShowDelete = false"
      v-if="isShowDelete"
      @accept="onDelete"
    />

    <ConfirmDialog
      :text="tn('delete_selected_alert', { 0: selectedRows.length })"
      @close="isShowBasketsDelete = false"
      v-if="isShowBasketsDelete"
      @accept="onDeleteSelected"
    />

    <div class="d-flex align-center">
      <v-menu
        offset-y
        offset-x
        nudge-left="5"
        nudge-top="5"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="primary"
            min-width="40"
            width="40"
            height="40"
            min-height="40"
            outlined
            :disabled="!selectedRows.length"
            :loading="isLoading"
            class="mr-2"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon> mdi-menu </v-icon>
          </v-btn>
        </template>
        <v-list dense>
          <template v-for="(actionItem, index) in actionsListMulti">
            <v-list-item
              :key="index"
              @click="actionItem.action"
              :class="actionItem.class"
            >
              <v-list-item-title>
                {{ actionItem.name }}
              </v-list-item-title>
            </v-list-item>
            <v-divider
              v-if="index < actionsListMulti.length - 1"
              :key="index + 'i'"
            />
          </template>
        </v-list>
      </v-menu>

      <v-spacer />

      <div class="mx-1" />

      <v-btn
        color="primary"
        min-width="40"
        width="40"
        height="40"
        min-height="40"
        outlined
        @click="$emit('refresh')"
        :disabled="isLoading"
        :loading="isLoading"
        :title="tn('refresh_btn')"
        class="mr-2"
      >
        <v-icon> mdi-sync </v-icon>
      </v-btn>

      <table-cog
        :headersData="tableHeaders"
        @apply="(v) => (filteredHeaders = $compareArray(tableHeaders, v, 'value'))"
        :cacheKey="uniqKey"
      />

      <div class="my-7" />
      <div class="mx-1" />

      <v-text-field
        outlined
        hide-details
        dense
        append-icon="mdi-magnify"
        @click:append="onSearch"
        @keyup.enter="onSearch"
        @click:clear="onClear"
        style="max-width: 200px; width: 100%"
        :label="tn('search')"
        clearable
        v-model="searchText"
        ref="searchBox"
      />
    </div>

    <slot />

    <div class="mt-1" />

    <v-data-table
      ref="table"
      v-if="filteredHeaders.length && tableHeight > 0"
      dense
      key="guid"
      item-key="guid"
      show-select
      fixed-header
      disable-pagination
      hide-default-footer
      v-model="selectedRows"
      class="remove-checkbox-margin"
      checkbox-color="primary"
      :headers="realHeaders"
      :height="tableHeight"
      :mobile-breakpoint="0"
      :loading="isLoading"
      :options.sync="options"
      :items="entries"
      :server-items-length="entriesCount"
      :sort-by="sortBy"
      @click:row="onClickRow"
      v-resize-table="{ tableHeaders: realHeaders, callback: onUpdateTdSizes }"
    >
      <template v-slot:item.providerName="{ item, header, index }">
        <table-focus-input
          v-if="isFocusable"
          :ref="item.id"
          :tabindex="index + 1"
          :item="item"
          :uniKey="uniqKey"
          @upKey="onUpKey({ item })"
          @downKey="onDownKey({ item })"
          @pageUp="onPageUp({ item })"
          @pageDown="onPageDown({ item })"
          @enter="onKeyUpEnter({ item })"
        />

        <div
          class="d-flex align-center"
          :style="{ width: tdSizes[header.value] || header.width, minWidth: tdSizes[header.value] || header.minWidth }"
        >
          <div class="tb-action-wrap-2">
            <v-menu
              offset-y
              offset-x
              nudge-left="5"
              nudge-top="5"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  min-height="28"
                  height="28"
                  min-width="28"
                  width="28"
                  depressed
                  color="transparent"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon> mdi-menu </v-icon>
                </v-btn>
              </template>

              <v-list dense>
                <template v-for="(actionItem, index) in actionsList">
                  <v-list-item
                    :key="index"
                    @click="actionItem.action(item)"
                    :class="actionItem.class"
                  >
                    <v-list-item-title>
                      {{ actionItem.name }}
                    </v-list-item-title>
                  </v-list-item>
                  <v-divider
                    v-if="index < actionsList.length - 1"
                    :key="index + 'i'"
                  />
                </template>
              </v-list>
            </v-menu>
          </div>

          {{ item.providerName }}
        </div>
      </template>

      <template v-slot:item.itemsCount="{ item, header }">
        <div
          style="display: flex; justify-content: space-between"
          class="pr-2"
        >
          <div
            class="text-nowrap"
            v-html="header._filter(item[header.value], item)"
            :style="{ width: tdSizes[header.value] || header.width, minWidth: tdSizes[header.value] || header.minWidth }"
          />
        </div>
      </template>

      <template v-slot:item.totalAmount="{ item, header }">
        <div
          class="text-nowrap text-right"
          v-html="header._filter(item[header.value], item)"
          :style="{ width: tdSizes[header.value] || header.width, minWidth: tdSizes[header.value] || header.minWidth }"
        />
      </template>

      <template v-slot:item.timeStarted="{ item, header }">
        <div
          class="text-nowrap text-right"
          v-html="header._filter(item[header.value], item)"
          :style="{ width: tdSizes[header.value] || header.width, minWidth: tdSizes[header.value] || header.minWidth }"
        />
      </template>

      <template
        v-if="entriesCount > 1"
        v-slot:body.append
      >
        <tr class="sticky-table-footer">
          <td
            v-for="(header, i) in realHeaders"
            :key="i"
          >
            <div
              v-if="header.value == 'data-table-select'"
              class="text-nowrap text-center"
            >
              {{ entriesCount | sum }}
            </div>
          </td>
        </tr>
      </template>

      <template #footer>
        <TablePaginationButtons
          :loading="isLoading"
          :options="options"
          :lastPage="lastPage"
          :perPageInput="perPageInput"
          :pageInput="pageInput"
          :entriesCount="entriesCount"
          :onLastPage="onLastPage"
          :onChangePerPageInput="onChangePerPageInput"
          :onChangePageInput="onChangePageInput"
        />
      </template>
    </v-data-table>
  </div>
</template>
