<script>
import tnVue from "@/mixins/tn.vue";
import AppUpdateNotificationModel from "./components/app-update-notification-modal.vue";
import DeadlineTimer from "@/components/deadline-timer.vue";

let initUpdateTimeout = null;

const minutes20 = 1.2e6;

export default {
  components: { AppUpdateNotificationModel, DeadlineTimer },

  mixins: [tnVue],

  data: () => ({
    isShowAsInline: false,
    isShowInfo: false,
    updateData: {},
  }),

  created() {
    this.getUpdateData();
  },

  beforeDestroy() {
    if (initUpdateTimeout) clearTimeout(initUpdateTimeout);
  },

  methods: {
    onDeadline() {
      this.$store.dispatch("auth/LOGOUT");
      window.location.reload();
    },

    async getUpdateData() {
      try {
        const time = Math.ceil(new Date().getTime() / 1000);
        const res = await fetch(`/update.json?r=${time}`, { cache: "no-cache" }).then((x) => x.json());
        this.updateData = {};

        this.$nextTick(() => {
          this.updateData = res?.updateStartDateTime ? res : {};

          if (this.$version != this.updateData.actualVersion) {
            this.$emit("actualVersion", this.updateData.actualVersion);
          } else {
            const diff = this.$getDiffMilliseconds(this.updateData.updateStartDateTime, { allowMinus: true });

            if ((diff, 1000 > diff && diff > -100000)) {
              this.onDeadline();
              return;
            }

            this.isShowInfo = this.updateData.newVersion != this.$version && diff > 0 && diff <= minutes20;
          }
        });
      } catch (e) {
        console.log(e);
      }

      if (initUpdateTimeout) clearTimeout(initUpdateTimeout);
      initUpdateTimeout = setTimeout(this.getUpdateData, this.isShowInfo ? 60000 : 180000);
    },
  },
};
</script>

<template>
  <div v-if="isShowInfo">
    <div
      v-if="isShowAsInline"
      class="_update-inline-timer"
      @click="isShowAsInline = false"
    >
      <DeadlineTimer
        v-if="updateData?.updateStartDateTime"
        :deadline="$getDiffMilliseconds(updateData.updateStartDateTime) - 10000"
        @end="onDeadline"
      />
    </div>

    <AppUpdateNotificationModel
      v-else
      :update-data="updateData"
      @close="isShowAsInline = true"
      @end="onDeadline"
    >
      <template #deadline>
        <DeadlineTimer
          v-if="updateData?.updateStartDateTime"
          :deadline="$getDiffMilliseconds(updateData.updateStartDateTime) - 10000"
          @end="onDeadline"
        />
      </template>
    </AppUpdateNotificationModel>
  </div>
</template>

<style lang="scss" scoped>
._update-inline-timer {
  position: absolute;
  z-index: 9999999999999;
  bottom: 8px;
  left: 8px;
  padding: 4px 10px;
  border-radius: 12px;
  color: #fff;
  cursor: pointer;
  background-color: #ff0505;
}
</style>
