export const timeNames = {
  seconds: ["секунда", "секунды", "секунд"],
  minutes: ["минута", "минуты", "минут"],
  hours: ["час", "часа", "часов"],
  days: ["день", "дня", "дней"],
};

export const getTimeName = (t, names) => {
  var m = Math.abs(t) % 100;
  var n1 = m % 10;
  if (m > 10 && m < 20) return names[2];
  if (n1 > 1 && n1 < 5) return names[1];
  if (n1 == 1) return names[0];
  return names[2];
};

// for (let index = 0; index < 25; index++) {
//   const t = index + 1
//   console.log(t, ' - ', getTimeName(t, timeNames.days))
// }
