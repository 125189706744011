<script>
import { mapActions, mapGetters } from "vuex";
import debounce from "debounce";

import tnVue from "@/mixins/tn.vue";

import TableCog from "@/components/table-cog.vue";
import TableFocusInput from "./table-focus-input.vue";
import TableResizeVue from "@/mixins/table-resize.vue";
import TablePagination from "@/mixins/table-pagination.vue";
import TableNavFunctionsVue from "@/mixins/table-nav-functions.vue";

import ConfirmDialog from "@/components/modals/confirm-dialog.vue";
import PermissionConfirmDialog from "@/components/modals/permission-confirm-dialog.vue";
import ShareContact from "./share-contact/share-contact.vue";

export default {
  components: {
    TableCog,
    ShareContact,
    ConfirmDialog,
    TableFocusInput,
    PermissionConfirmDialog,
  },

  props: {
    sortBy: String,
    tableHeight: Number,
    ln: String,
    isFocusable: Boolean,
  },

  data: (vm) => ({
    options: vm.$getSortLocal({
      key: "basket-provider-list",
      options: {
        page: vm.$number(localStorage.getItem(`doc-page-basket-provider-list`)) || 1,
      },
    }),
    selectedRows: vm.$browserStore.getSession("selectedRows") || [],

    isShowDelete: false,
    uniqKey: "basket-provider-list",
    isShowBasketClose: false,
    isCreateOrder: false,
    isShowBasketCloseMulti: false,

    isShowBasketsDelete: false,
    note: null,

    isPermissionAlert: false,
    tryCount: null,

    isSharingContact: false,
  }),

  mixins: [tnVue, TablePagination, TableResizeVue, TableNavFunctionsVue],

  computed: {
    ...mapGetters("auth", ["GET_USER", "GET_USER_PERMISSIONS"]),
    ...mapGetters("basket", {
      entries: "GET_PRICE_LIST_PROVIDERS",
      entriesCount: "GET_PRICE_LIST_PROVIDERS_COUNT",
      entriesTotals: "GET_TOTALS",
    }),

    isMenuOrderPermission: (vm) => vm.$store.state.auth.isMenuOrderPermission,

    tableHeaders: (vm) =>
      [
        // {
        //   text: "№",
        //   value: "number",
        //   width: 10,
        //   sortable: false,
        //   class: "disable-resize",
        // },
        {
          text: vm.tn("table.priceListProviderName"),
          value: "priceListProviderName",
          width: vm.$dxcs.priceListProviderName,
          _required: true,
        },
        {
          text: vm.tn("table.totalAmount"),
          value: "totalAmount",
          _filter: (v) => vm.$sum(v),
          _required: true,
          width: vm.$dxcs.totalAmount,
          class: "disable-resize",
        },
        {
          text: vm.tn("table.totalAmountDiscount"),
          value: "totalAmountDiscount",
          _filter: (v) => vm.$sum(v),
          _required: true,
          class: "disable-resize",
        },
        {
          text: vm.tn("table.priceTypeName"),
          value: "priceTypeName",
          width: vm.$dxcs.priceTypeName,
          _required: true,
        },
        {
          text: vm.tn("table.itemsCount"),
          value: "itemsCount",
          _filter: (v) => vm.$sum(v),
          _required: true,
        },
      ].map((x) => {
        if (vm.tdSizes[x.value]) x.width = vm.tdSizes[x.value];
        return x;
      }),

    realHeaders: (vm) => [
      {
        width: 10,
        class: "disable-resize disable-tr-size",
        value: "data-table-select",
      },
      ...vm.filteredHeaders,
    ],

    actionsList: (vm) => [
      {
        action: (item) => (item.isAvailableBasketToOrder && vm.isMenuOrderPermission ? vm.onCreateOrderShow({ item }) : vm.onCloseBasketShow({ item })),
        value: "isAvailable",
      },
      {
        name: vm.tn("actions.export_excel_btn"),
        action: (item) => vm.onExportExcel({ item }),
      },
      {
        name: vm.tn("actions.download_excel_telegram_btn"),
        action: (item) => vm.onExportExcelTelegram({ item }),
      },
      {
        name: vm.tn("actions.share_contact"),
        action: (item) => vm.onSharingContact({ item }),
      },
      {
        name: vm.tn("actions.show_price_btn"),
        action: (item) => {
          window.open(`/price-list/${item.priceListGuid}`, "_blank");
        },
      },
      {
        name: vm.tn("actions.info_btn"),
        action: (item) => vm.$windowOpen("price-list-provider", item.priceListProviderGuid, item.priceListProviderName, 650, 800, "preview=1"),
      },
      {
        name: vm.tn("actions.delete_btn"),
        action: (item) => vm.onDeleteShow({ item }),
        class: "error--text",
      },
    ],

    actionsListMulti: (vm) => [
      {
        name: `${vm.tn("actions.send_order_btn")} / ${vm.tn("actions.close_basket_btn")}`,
        // name: vm.tn("actions.close_basket_btn"),
        action: () => (vm.isShowBasketCloseMulti = true),
      },
      {
        name: vm.tn("actions.download_excel_telegram_btn"),
        action: () => vm.onExportExcelTelegramMulti(),
      },
      {
        name: vm.tn("actions.delete_btn"),
        action: () => {
          vm.selectedRows = vm.selectedRows.filter((x) => vm.entries.find((y) => y.guid == x.guid));
          vm.isShowBasketsDelete = true;
        },
        class: "error--text",
      },
    ],
  },

  created() {
    this.selectedRows = this.selectedRows.filter((row) => this.entries.some((entry) => entry.guid === row.guid));
    if (this.selectedRows.length) this.$browserStore.setSession("selectedRows", [...this.selectedRows]);
  },

  watch: {
    selectedRows(v = []) {
      this.$emit("selectedRows", [...v]);
      this.$browserStore.setSession("selectedRows", [...v]);
    },
  },

  methods: {
    ...mapActions("basket", {
      getEntriesApi: "GET_PRICE_LIST_PROVIDERS_API",
      deleteApi: "REMOVE_API",
      closeBasketApi: "CLOSE_BASKET_API",
      deleteSelectedBaskets: "DELETE_SELECTED_BASKETS_API",
    }),

    ...mapActions("auth", ["GET_PERMISSIONS_LIST"]),

    async getEntries(ee) {
      const isReset = ee?.reset;

      let loaders = { ...this.loaders };
      const loadKey = this.$uuid();
      loaders[loadKey] = true;
      this.loaders = loaders;

      this.$setSortLocal({
        key: "basket-provider-list",
        options: this.options,
      });

      const params = {
        options: {
          limit: this.options.itemsPerPage,
          offset: this.options.itemsPerPage * (this.options.page - 1),
          sortDirection: this.$getSort(this.options.sortDesc, true),
          sortBy: this.$getSort(this.options.sortBy),
          search: this.searchText,
        },
      };

      if (isReset) {
        this.options.page = 1;
        params.options.offset = 0;
      }

      params.filterData = {};

      await this.getEntriesApi(params);

      if (!this.checkFocused()) {
        this.setFocusToItem();
        // if (!this.selectedRows.length && this.entries.length) {
        //   this.$refs.table?.select(this.entries[0]);
        // }
      } else {
        this.selected = {};
      }

      loaders = { ...this.loaders };
      delete loaders[loadKey];
      this.loaders = loaders;
    },

    checkFocused() {
      return document.activeElement.className.includes(this.uniqKey);
    },

    onSearch: debounce(function () {
      this.options.page = 1;
      this.$nextTick(() => this.getEntries());
    }, 200),

    onKeyup(e) {
      if (e.keyCode == 113) this.$refs.searchBox?.focus();
    },

    onClear() {
      this.searchText = null;
      this.$nextTick(() => this.getEntries());
    },

    onKeyUpEnter({ item }) {
      const isSelected = this.$refs.table?.isSelected(item);
      this.$refs.table?.select(item, !isSelected);
    },

    onSelect({ item }) {
      this.selected = { ...item };
      this.$setInputFocus(item._id, this.uniqKey);
    },

    onDeleteShow({ item }) {
      this.selected = { ...item };
      this.$nextTick(() => (this.isShowDelete = true));
    },

    async onDelete({ close, setLoading }) {
      setLoading(true);
      const params = { basketGuid: this.selected.guid };
      const status = await this.deleteApi(params);
      if (status) {
        close();
        this.selectedRows = this.selectedRows.filter((x) => x.guid != params.basketGuid);

        this.getEntries();
      }
      setLoading(false);
    },

    onClickRow(item, params) {
      if (item && params) {
        this.onSelect({ item });
        this.onKeyUpEnter({ item });
      }
    },

    async onExportExcel({ item }) {
      if (!this.GET_USER.isTrial) {
        this.selected = item;
        this.$nextTick(() => this.onPermission());
      } else {
        this.$store.dispatch("SET_LOADING", true);
        await this.GET_PERMISSIONS_LIST();
        this.$store.dispatch("SET_LOADING", false);

        this.selected = item;
        this.tryCount = this.$getObjectParam("CanExportToExcelBasket", this.GET_USER_PERMISSIONS);
        if (this.$number(this.tryCount)) {
          this.isPermissionAlert = true;
        } else {
          this.$store.dispatch("SET_SNACKBAR", {
            text: this.$t("permission_confirm_dialog.permission_alert"),
            color: "error",
          });
        }
      }
    },

    onSharingContact({ item }) {
      this.selected = { ...item };
      this.$nextTick(() => (this.isSharingContact = true));
    },

    async onExportExcelTelegram({ item }) {
      this.selected = { ...item };

      this.$store.dispatch("SET_LOADING", true);

      const url = `Basket/${item.guid}/SendWithBot`;
      const res = await this.$axios.$post(url);
      if (res?.succeeded) {
        if (res.data == 1) {
          this.onCloseBasketShow({ item });
        } else if (res.data == 0) {
          this.$store.dispatch("SET_SNACKBAR", {
            text: this.tn("first_link_bot_account_info"),
            color: "info",
          });
          this.$router.push("/telegram-bot-connect");
        } else if (res.data == -1) {
          this.$store.dispatch("SET_SNACKBAR", {
            text: this.tn("error_sending_bot_file_info"),
            color: "info",
          });
        }
      }

      this.$store.dispatch("SET_LOADING", false);
    },

    async onCloseBasketMulti({ close }) {
      if (!this.$refs.form.validate()) return;

      this.$store.dispatch("SET_LOADING", true);

      for (const row of this.selectedRows) {
        await this.closeBasketApi({ guid: row.guid, note: this.note });
        this.$nextTick(() => ((this.note = null), (this.selectedRows = this.selectedRows.filter((x) => x.guid != row.guid))));
      }

      close();

      this.$store.dispatch("SET_LOADING", false);
      this.$emit("refresh");
    },

    async onExportExcelTelegramMulti() {
      this.$store.dispatch("SET_LOADING", true);

      for (const row of this.selectedRows) {
        const url = `Basket/${row.guid}/SendWithBot`;
        const res = await this.$axios.$post(url);
        if (res?.succeeded) {
          if (res.data == 0) {
            this.$store.dispatch("SET_SNACKBAR", {
              text: this.tn("first_link_bot_account_info"),
              color: "info",
            });
            this.$router.push("/telegram-bot-connect");
            break;
          } else if (res.data == -1) {
            this.$store.dispatch("SET_SNACKBAR", {
              text: this.tn("error_sending_bot_file_info"),
              color: "info",
            });
            break;
          }
        }
      }

      this.$store.dispatch("SET_LOADING", false);
      this.$emit("refresh");
    },

    onCloseBasketShow({ item }) {
      this.selected = { ...item };
      this.$nextTick(() => (this.isShowBasketClose = true));
    },

    async onCloseBasket({ close, setLoading }) {
      setLoading(true);
      const status = await this.closeBasketApi({ guid: this.selected.guid });
      setLoading(false);
      if (status) {
        close();
        this.$emit("refresh");
        this.selectedRows = this.selectedRows.filter((x) => x.guid != this.selected.guid);
      }
    },

    onCreateOrderShow({ item }) {
      this.selected = { ...item };
      this.$nextTick(() => (this.isCreateOrder = true));
    },

    async onCreateOrder({ close, setLoading }) {
      if (this.$refs.form.validate()) {
        setLoading(true);

        const status = await this.closeBasketApi({
          guid: this.selected.guid,
          note: this.note,
        });

        setLoading(false);

        if (status) {
          close();
          this.$emit("refresh");
          this.selectedRows = this.selectedRows.filter((x) => x.guid != this.selected.guid);
        }
      }
    },

    async onDeleteSelected({ close, setLoading }) {
      setLoading(true);
      const status = await this.deleteSelectedBaskets({
        basketGuids: this.selectedRows.map((x) => x.guid),
      });

      setLoading(false);
      if (status) {
        close();
        this.$emit("refresh");
        this.selectedRows = [];
      }
    },

    getClass(item) {
      if (!item.isPriceListCurrent) {
        return "basket-row-error-color";
      }
    },

    async onPermission() {
      this.isPermissionAlert = false;
      this.$store.dispatch("SET_LOADING", true);

      const url = `Basket/${this.selected.guid}/Export`;
      const status = await this.$axios.$postResFile(url);
      if (status) this.onCloseBasketShow({ item: this.selected });

      this.$store.dispatch("SET_LOADING", false);
    },

    onOpen() {
      window.open("https://youtu.be/1wToBQJ5Xp8", "_blank");
    },
  },
};
</script>

<template>
  <div>
    <PermissionConfirmDialog
      :text="$t('permission_confirm_dialog.function_permission_alert')"
      :try-count="tryCount"
      @close="isPermissionAlert = false"
      v-if="isPermissionAlert"
      @accept="onPermission"
    />

    <ShareContact
      v-if="isSharingContact"
      :value="selected"
      :ln="`${ln}.share_contact`"
      @close="isSharingContact = false"
      @refresh="getEntries"
    />

    <ConfirmDialog
      :text="
        tn('delete_alert', {
          0: `<br />${selected.priceListProviderName} (${selected.priceTypeName})`,
        })
      "
      @close="isShowDelete = false"
      v-if="isShowDelete"
      @accept="onDelete"
    />

    <ConfirmDialog
      :text="tn('close_basket_alert')"
      @close="isShowBasketClose = false"
      v-if="isShowBasketClose"
      @accept="onCloseBasket"
    />

    <ConfirmDialog
      :text="tn('create_order_alert')"
      @close="isCreateOrder = false"
      v-if="isCreateOrder"
      @accept="onCreateOrder"
    >
      <v-form
        :disabled="isLoading"
        ref="form"
        lazy-validation
        onSubmit="return false;"
      >
        <v-textarea
          :label="tn('note')"
          autofocus
          outlined
          dense
          v-model="note"
          required
          :rules="[$rules.required, $rules.minSize(5)]"
        />
      </v-form>
    </ConfirmDialog>

    <ConfirmDialog
      :text="tn('close_basket_multi_alert', { 0: selectedRows.length })"
      @close="isShowBasketCloseMulti = false"
      v-if="isShowBasketCloseMulti"
      @accept="onCloseBasketMulti"
    >
      <div
        v-for="(item, index) in selectedRows"
        :key="index"
      >
        <div
          v-if="item.isAvailableBasketToOrder"
          class="text-start"
        >
          {{ index + 1 }} {{ item.priceListProviderName }}
        </div>
      </div>
    </ConfirmDialog>

    <ConfirmDialog
      :text="tn('create_order_multi_alert', { 0: selectedRows.length })"
      @close="isShowBasketCloseMulti = false"
      v-if="isShowBasketCloseMulti"
      @accept="onCloseBasketMulti"
    >
      <div
        v-for="(item, index) in selectedRows"
        :key="index"
      >
        <div
          v-if="item.isAvailableBasketToOrder"
          class="text-start"
        >
          {{ index + 1 }} {{ item.priceListProviderName }}
        </div>
      </div>
      <div
        class="text-start my-2"
        v-text="tn('does_not_accept_applications_in_the_system_osonprice_uz')"
      />
      <v-form
        :disabled="isLoading"
        ref="form"
        lazy-validation
        onSubmit="return false;"
      >
        <v-textarea
          :label="tn('note')"
          autofocus
          outlined
          dense
          v-model="note"
          required
          :rules="[$rules.required, $rules.minSize(5)]"
          hide-details
        />
      </v-form>
    </ConfirmDialog>

    <ConfirmDialog
      :text="tn('delete_selected_alert', { 0: selectedRows.length })"
      @close="isShowBasketsDelete = false"
      v-if="isShowBasketsDelete"
      @accept="onDeleteSelected"
    />

    <div class="d-flex align-center">
      <v-menu
        offset-y
        offset-x
        nudge-left="5"
        nudge-top="5"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="primary"
            min-width="40"
            width="40"
            height="40"
            min-height="40"
            outlined
            :disabled="!selectedRows.length"
            :loading="isLoading"
            class="mr-2"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon> mdi-menu </v-icon>
          </v-btn>
        </template>
        <v-list dense>
          <template v-for="(actionItem, index) in actionsListMulti">
            <v-list-item
              :key="index"
              @click="actionItem.action"
              :class="actionItem.class"
            >
              <v-list-item-title>
                {{ actionItem.name }}
              </v-list-item-title>
            </v-list-item>
            <v-divider
              v-if="index < actionsListMulti.length - 1"
              :key="index + 'i'"
            />
          </template>
        </v-list>
      </v-menu>

      <div class="px-1" />

      <v-tooltip bottom>
        <template #activator="{ on, attrs }">
          <v-icon
            size="30"
            color="success"
            v-bind="attrs"
            @click="onOpen"
            v-on="on"
          >
            mdi-chat-question-outline
          </v-icon>
        </template>
        <span> {{ $t("how_does_this_work") }} </span>
      </v-tooltip>
      <v-spacer />

      <div class="mx-1" />

      <v-btn
        color="primary"
        min-width="40"
        width="40"
        height="40"
        min-height="40"
        outlined
        @click="$emit('refresh')"
        :disabled="isLoading"
        :loading="isLoading"
        :title="tn('refresh_btn')"
        class="mr-2"
      >
        <v-icon> mdi-sync </v-icon>
      </v-btn>

      <table-cog
        :headersData="tableHeaders"
        @apply="(v) => (filteredHeaders = $compareArray(tableHeaders, v, 'value'))"
        :cacheKey="uniqKey"
      />

      <div class="my-7" />
      <div class="mx-1" />

      <v-text-field
        outlined
        hide-details
        dense
        append-icon="mdi-magnify"
        @click:append="onSearch"
        @keyup.enter="onSearch"
        @click:clear="onClear"
        style="max-width: 200px; width: 100%"
        :label="tn('search')"
        clearable
        v-model="searchText"
        ref="searchBox"
      />
    </div>

    <slot />

    <div class="mt-1" />

    <v-data-table
      ref="table"
      v-if="filteredHeaders.length && tableHeight > 0"
      dense
      key="guid"
      item-key="guid"
      show-select
      fixed-header
      disable-pagination
      hide-default-footer
      v-model="selectedRows"
      class="remove-checkbox-margin"
      checkbox-color="primary"
      :headers="realHeaders"
      :height="tableHeight"
      :mobile-breakpoint="0"
      :loading="isLoading"
      :options.sync="options"
      :items="entries"
      :server-items-length="entriesCount"
      :sort-by="sortBy"
      :item-class="getClass"
      @click:row="onClickRow"
      v-resize-table="{ tableHeaders: realHeaders, callback: onUpdateTdSizes }"
    >
      <template v-slot:item.priceListProviderName="{ item, header, index, attrs }">
        <td
          v-bind="attrs"
          class="pa-0"
        >
          <table-focus-input
            v-if="isFocusable"
            :ref="item.id"
            :tabindex="index + 1"
            :item="item"
            :uniKey="uniqKey"
            @upKey="onUpKey({ item })"
            @downKey="onDownKey({ item })"
            @pageUp="onPageUp({ item })"
            @pageDown="onPageDown({ item })"
            @enter="onKeyUpEnter({ item })"
          />

          <div
            :title="!item.isPriceListCurrent ? tn('expiry_date_alert') : undefined"
            class="d-flex align-center px-2"
            :style="{ width: tdSizes[header.value] || header.width, minWidth: tdSizes[header.value] || header.minWidth, backgroundColor: !!(item?.promotionType == 0) ? '#0ff0fc59' : '' }"
          >
            <div class="tb-action-wrap-2">
              <v-menu
                offset-y
                offset-x
                nudge-left="5"
                nudge-top="5"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    min-height="28"
                    height="28"
                    min-width="28"
                    width="28"
                    depressed
                    color="transparent"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon> mdi-menu </v-icon>
                  </v-btn>
                </template>

                <v-list dense>
                  <template v-if="!item.isPriceListCurrent">
                    <v-list-item
                      class="primary--text"
                      :to="`/basket/fresh/${item.guid}`"
                    >
                      <v-list-item-title>
                        {{ tn("actions.replace_items") }}
                      </v-list-item-title>
                    </v-list-item>
                    <v-divider />
                  </template>

                  <template v-for="(actionItem, index) in actionsList">
                    <v-list-item
                      :key="index"
                      @click="actionItem.action(item)"
                      :class="actionItem.class"
                    >
                      <v-list-item-title v-if="actionItem.value == 'isAvailable'">
                        {{ item.isAvailableBasketToOrder && isMenuOrderPermission ? tn("actions.send_order_btn") : tn("actions.close_basket_btn") }}
                      </v-list-item-title>

                      <v-list-item-title v-else>
                        {{ actionItem.name }}
                      </v-list-item-title>
                    </v-list-item>
                    <v-divider
                      v-if="index < actionsList.length - 1"
                      :key="index + 'i'"
                    />
                  </template>
                </v-list>
              </v-menu>
            </div>

            <div>
              <v-tooltip
                bottom
                color="green"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-if="!!(item?.promotionType == 0)"
                    v-bind="attrs"
                    v-on="on"
                    color="green"
                  >
                    mdi-cash-plus
                  </v-icon>
                </template>
                <span>Oson Promotion</span>
              </v-tooltip>

              {{ item.priceListProviderName }}
            </div>
          </div>
        </td>
      </template>

      <template v-slot:item.itemsCount="{ item, header }">
        <div
          style="display: flex; justify-content: space-between"
          class="pr-2"
        >
          <div
            class="text-nowrap"
            v-html="header._filter(item[header.value], item)"
            :style="{ width: tdSizes[header.value] || header.width, minWidth: tdSizes[header.value] || header.minWidth }"
          />
          <!-- <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-if="!item.isAvailableBasketToOrder"
                size="20"
                v-bind="attrs"
                v-on="on"
              >
                mdi-file-document
              </v-icon>
              <v-icon
                v-else
                size="20"
                v-bind="attrs"
                v-on="on"
              >
                mdi-file-document-outline
              </v-icon>
            </template>
  <span>
    {{
    !item.isAvailableBasketToOrder ?
    tn('does_not_accept_applications_in_the_system_osonprice_uz') :
    tn('accepts_applications_in_the_system_osonprice_uz')
    }}
  </span>
  </v-tooltip> -->
        </div>
      </template>

      <template v-slot:item.totalAmount="{ item, header }">
        <div
          class="text-nowrap text-right"
          v-html="header._filter(item[header.value], item)"
          :style="{ width: tdSizes[header.value] || header.width, minWidth: tdSizes[header.value] || header.minWidth }"
        />
      </template>

      <template v-slot:item.totalAmountDiscount="{ item, header }">
        <div
          class="text-nowrap text-right"
          v-html="header._filter(item[header.value], item)"
          :style="{ width: tdSizes[header.value] || header.width, minWidth: tdSizes[header.value] || header.minWidth }"
        />
      </template>

      <template
        v-if="entriesCount > 1"
        v-slot:body.append
      >
        <tr class="sticky-table-footer">
          <td
            v-for="(header, i) in realHeaders"
            :key="i"
          >
            <div
              v-if="header.value == 'data-table-select'"
              class="text-nowrap text-center"
            >
              {{ entriesCount | sum }}
            </div>
            <div
              v-if="header.value == 'priceListProviderName'"
              class="text-nowrap"
            >
              {{
                tn("distributors_total", {
                  0: $sum(entriesTotals.totalProviderCount),
                })
              }}
            </div>
            <div
              v-if="header.value == 'totalAmount'"
              class="text-nowrap text-right"
            >
              {{ entriesTotals.totalAmount | sum }}
            </div>
            <div
              v-if="header.value == 'totalAmountDiscount'"
              class="text-nowrap text-right"
            >
              {{ entriesTotals.totalAmountDiscount | sum }}
            </div>
            <div
              v-if="header.value == 'itemsCount'"
              class="text-nowrap"
            >
              {{ entriesTotals.totalItemsCount | sum }}
            </div>

            <div v-else />
          </td>
        </tr>
      </template>

      <template #footer>
        <TablePaginationButtons
          :loading="isLoading"
          :options="options"
          :lastPage="lastPage"
          :perPageInput="perPageInput"
          :pageInput="pageInput"
          :entriesCount="entriesCount"
          :onLastPage="onLastPage"
          :onChangePerPageInput="onChangePerPageInput"
          :onChangePageInput="onChangePageInput"
        />
      </template>
    </v-data-table>
  </div>
</template>
