import Vue from "vue";
const vm = Vue.prototype;
import router from "@/router";
import { setRealTime } from "@/plugins/get-real-time";

const url = "priceListViewer";

export const state = () => ({
  user: {},
  permissions: {},
  isMenuOrderPermission: null,
});

export const mutations = {
  SET_USER(state, user) {
    user = user || {};
    user.exist = true;
    state.user = user;
  },

  SET_MENU_ORDER_PERMISSION(state, isMenuOrderPermission) {
    state.isMenuOrderPermission = isMenuOrderPermission;
  },

  SET_PRICE_LIST_PERMISSION(state, payload) {
    state.permissions = {
      items: vm.$array(payload?.items),
      total: vm.$number(payload?.totalCount),
    };
  },

  LOGOUT(state) {
    state.user = {};
    vm.$cookies.remove("osonprice_token");
    vm.$cookies.remove("user");
    localStorage.removeItem("how-to-use-from-selection-expiration-date");
    localStorage.removeItem("how-to-use-from-selection-view-count");
    localStorage.removeItem("how-to-use-from-selection-is-visible");
  },
};

export const actions = {
  async GET_USER_API() {
    const res = await vm.$axios.$get(`${url}/me`);
    return res?.succeeded ? res.data : null;
  },

  async GET_PERMISSIONS_LIST({ commit }) {
    const res = await vm.$axios.$get(`${url}Permission`);
    commit("SET_PRICE_LIST_PERMISSION", res.data);
    return res?.succeeded ? res.data : null;
  },

  async GET_MENU_ORDER_PERMISSION({ commit }) {
    const _url = "PriceListViewerPermission/Check/Order";
    const res = await vm.$axios.$get(_url);
    commit("SET_MENU_ORDER_PERMISSION", res.data);
    return res?.succeeded ? res.data : null;
  },

  async GET_REDUCE_TRY_COUNT(ctx, payload) {
    const res = await vm.$axios.$put(`${url}Permission/ReduceTryCount`, payload);
    return res?.succeeded ? res.data : null;
  },

  async INIT_USER({ dispatch, commit }) {
    const token = vm.$cookies.get("osonprice_token");
    if (!token) return;
    const user = await dispatch("GET_USER_API");
    if (user && user.serverTime) {
      const timeData = {
        local: new Date().getTime(),
        server: Date.parse(user.serverTime),
      };
      setRealTime(timeData);

      commit("SET_USER", user);
      await dispatch("GET_MENU_ORDER_PERMISSION");

      if (user.isTrial) {
        const ppStatus = await dispatch("GET_PERMISSIONS_LIST");
        if (ppStatus) commit("SET_PRICE_LIST_PERMISSION", ppStatus);
      }
    }
  },

  async EDIT_PROFILE(ctx, payload) {
    const res = await vm.$axios.$post(`${url}/edit`, payload);
    return res?.succeeded;
  },

  async REGISTER(ctx, payload) {
    const res = await vm.$axios.$post(`${url}/register`, payload);
    const status = res?.succeeded;
    return status;
  },

  async RECAPTCHA(ctx, payload) {
    const res = await vm.$axios.$post(`${url}/recaptcha`, payload);
    return res?.succeeded ? res.data.image : null;
  },

  async CONFIRM_RECAPTCHA(ctx, payload) {
    const res = await vm.$axios.$post(`${url}/confirmRecaptcha`, payload);
    return res?.succeeded;
  },

  async RE_SEND_SMS_CODE(ctx, payload) {
    const res = await vm.$axios.$post(`${url}/reSendSmsCode`, payload);
    return res?.succeeded;
  },

  async AUTH(ctx, auth) {
    const res = await vm.$axios.$post(`${url}/auth`, auth);
    return res?.succeeded ? res.data : null;
  },

  async CONFIRM_REGISTER_SMS_CODE({ dispatch }, payload) {
    const res = await vm.$axios.$post(`${url}/authRegisterToken`, payload);
    const status = res?.succeeded;
    if (status) {
      vm.$cookies.set("osonprice_token", res.data.token, res.data.expireAt + "d");
      await dispatch("INIT_USER");
      await dispatch("INIT_STATES", null, { root: true });
      vm.$nextTick(() => router.replace("/home"));
    }
    return status;
  },

  async CONFIRM_AUTH_TOKEN({ dispatch }, payload) {
    const res = await vm.$axios.$post(`${url}/authToken`, payload);
    const status = res?.succeeded;
    if (status) {
      vm.$cookies.set("osonprice_token", res.data.token, res.data.expireAt + "d");
      await dispatch("INIT_USER");
      await dispatch("INIT_STATES", null, { root: true });
      vm.$nextTick(() => {
        const returnUrl = `${sessionStorage.getItem("auth-return-url") || ""}`;
        sessionStorage.removeItem("auth-return-url");
        router.replace(returnUrl.length ? returnUrl : "/home");
      });
    }
    return status;
  },

  LOGOUT({ commit }) {
    commit("LOGOUT");
    router.replace("/logout=1");
  },
};

export const getters = {
  GET_USER: (state) => state.user,
  GET_USER_PERMISSIONS: (state) => state.permissions.items,
};
