<script>
import tnVue from "@/mixins/tn.vue";
import ModalsTemplate from "@/components/modals-template.vue";
import { getTimeName, timeNames } from "@/plugins/time-name";

export default {
  components: { ModalsTemplate },

  mixins: [tnVue],

  props: {
    updateData: Object,
  },

  data: () => ({
    ln: "app_update_notification",
    dialog: true,
  }),

  computed: {
    durationView() {
      const minutes = this.$number(this.updateData?.updateDurationInMinutes);
      if (minutes > 0) return `${minutes} ${getTimeName(minutes, timeNames.minutes)}`;
      return null;
    },
  },

  watch: {
    dialog(v) {
      if (!v) setTimeout(() => this.$emit("close"), 150);
    },
  },

  methods: {
    onApplyBtn() {
      this.$nextTick(() => (this.dialog = false));
    },
  },
};
</script>

<template>
  <ModalsTemplate
    v-model="dialog"
    :title="tn('title')"
    :width="500"
  >
    <div v-text="tn('site_will_be_updated')" />

    <div class="_after-minutes error--text d-flex">
      <slot name="deadline" />

      <div
        class="pl-2"
        v-text="`(${tn('today_at', { time: $time(updateData?.updateStartDateTime) })})`"
      />
    </div>

    <div v-html="tn('app_stopped_during_update')" />
    <div v-if="durationView">
      {{ tn("update_duration") }}
      <span
        class="warning--text"
        v-text="durationView"
      />
    </div>

    <div class="d-flex justify-center pt-7">
      <v-btn
        dark
        depressed
        width="60%"
        color="primary"
        @click="onApplyBtn"
      >
        {{ tn("ok_btn") }}
      </v-btn>
    </div>
  </ModalsTemplate>
</template>

<style lang="scss" scoped>
._after-minutes {
  font-size: 40px;
  padding: 25px 0;
}
</style>
