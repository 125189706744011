<script>
import { getTimeName, timeNames } from "@/plugins/time-name";

const fz = (value) => (value ? `0${value}`.slice(-2) : "00");
let timerFunction;

export default {
  props: {
    deadline: Number, // milliseconds
    redTextTime: Number, // milliseconds
  },

  data: (vm) => ({
    currentTime: {},
    deadlineAll: vm.deadline > 0 ? vm.deadline + 2 : 0,
    currentSeconds: vm.deadline > 0 ? vm.deadline + 2 : 0,
  }),

  computed: {
    timerView() {
      const { days = 0, hours = 0, minutes = 0, seconds = 0 } = this.currentTime || {};
      const _days = days > 0 ? `${days < 10 ? fz(days) : days} ${getTimeName(days, timeNames.days)} ` : "";
      const _hours = hours > 0 ? `${fz(hours)}:` : "";
      const _minutes = `${fz(minutes)}:`;
      const _seconds = `${fz(seconds)}`;
      return `${_days}${_hours}${_minutes}${_seconds}`;
    },
  },

  created() {
    this.countdown();
  },

  beforeUnmount() {
    if (timerFunction) clearTimeout(timerFunction);
  },

  methods: {
    countdown() {
      if (this.deadlineAll > 0) {
        const ms = (this.currentSeconds -= 1000);

        if (ms > 0) {
          const days = Math.floor(ms / (24 * 60 * 60 * 1000));
          const days_ms = ms % (24 * 60 * 60 * 1000);
          const hours = Math.floor(days_ms / (60 * 60 * 1000));
          const hours_ms = ms % (60 * 60 * 1000);
          const minutes = Math.floor(hours_ms / (60 * 1000));
          const minutes_ms = ms % (60 * 1000);
          const seconds = Math.floor(minutes_ms / 1000);

          this.currentTime = {
            total: ms,
            days,
            hours,
            minutes,
            seconds,
          };

          timerFunction = setTimeout(this.countdown, 1000);
        } else {
          this.currentTime = {};
          this.$emit("end");
        }
      }
    },
  },
};
</script>

<template>
  <div
    :class="currentTime?.total <= redTextTime ? 'error--text' : ''"
    v-text="timerView"
  />
</template>
