<script>
import { mapActions, mapGetters } from "vuex";

import tnVue from "@/mixins/tn.vue";
import TableResizeVue from "@/mixins/table-resize.vue";
import TableNavFunctionsVue from "@/mixins/table-nav-functions.vue";

import PriceListItems from "@/components/price-list-items/price-list-items.vue";
import TableFilter from "@/components/modals/selection/table-filter.vue";
import ProductsList from "./components/products-list.vue";
import LettersFilterMenu from "@/components/letters-filter-menu.vue";
import ShowPharmacyRequirements from "@/components/show-pharmacy-requirements.vue";

const urlItemsByProduct = `PriceListLineItem/Product`;

export default {
  components: {
    PriceListItems,
    TableFilter,
    ProductsList,
    LettersFilterMenu,
    ShowPharmacyRequirements,
  },

  data: (vm) => ({
    ln: "selection",
    uniqKey: "selection-table2-cog",

    itemData: {},

    isFilter: false,
    filterData: vm.$getFilterData() || { isFavoriteProvider: null, isLinkedOnly: true },
    sortBy: "name",
    sortBy2: "amount",

    urlItemsByProduct,
    isShowAnalog: localStorage.getItem("is-show-analog") == "true" ? true : null,
    isShowReplacement: localStorage.getItem("is-show-replacement") == "true" ? true : null,

    isShowItems: false,
    isHide: null,
    isMyMarkup: false,
    markupText: null,
    isShowOrganizationStatistic: null,
    leftSideWidth: 454,
  }),

  mixins: [tnVue, TableResizeVue, TableNavFunctionsVue],

  computed: {
    size: (vm) => vm.$store.getters.GET_SIZE,

    ...mapGetters("price-list-items", ["GET", "GET_COUNT"]),

    entriesByProduct: (vm) => vm.GET(urlItemsByProduct),
    entriesCountByProduct: (vm) => vm.GET_COUNT(urlItemsByProduct),

    tableHeight: (vm) => vm.size.height - 170,

    tableHeaders: (vm) =>
      [
        {
          text: "№",
          value: "number",
          width: 10,
          sortable: false,
          _resize: false,
          class: "disable-resize",
        },
        {
          text: vm.tn("table.priceProvider"),
          value: "priceListProviderName",
          width: vm.$dxcs.priceListProviderName,
          _required: true,
        },
        {
          text: vm.tn("table.productName"),
          value: "productName",
          width: vm.$dxcs.productName,
          _remove: !vm.isShowAnalog && !vm.isShowReplacement,
          _slot: true,
        },
        {
          text: vm.tn("table.manufacturerName"),
          value: "manufacturerName",
          width: vm.$dxcs.manufacturer,
          _remove: !vm.isShowAnalog && !vm.isShowReplacement,
        },
        {
          text: vm.tn("table.shelfLife"),
          value: "shelfLife",
          width: vm.$dxcs.date,
          _filter: (v) => vm.$date(v),
        },
        {
          text: vm.tn("table.priceTypeName"),
          value: "priceTypeName",
          width: vm.$dxcs.priceTypeName,
        },
        {
          text: vm.tn("table.amount"),
          value: "amount",
          _filter: (v) => vm.$sum(v),
          _getColor: (v, item) => {
            const mxp = item.productReferentPriceWholeSalePrice;
            if (v && mxp && v > mxp) return "red";
          },
          width: vm.$dxcs.amount,
          _align: "right",
          _required: true,
        },
        {
          text: vm.tn("table.discount_amount"),
          value: "amountDiscount",
          _filter: (v) => vm.$sum(v),
          _getColor: (v, item) => {
            const mxp = item.productReferentPriceWholeSalePrice;
            if (v && mxp && v > mxp) return "red";
          },
          width: vm.$dxcs.amount,
          _align: "right",
        },
        {
          text: vm.tn("table.my_selling_price"),
          value: "mySellingPrice",
          sortable: false,
          visible: vm.isMyMarkup,
          _filter: (v, item) => {
            const a = item.amountDiscount || item.amount;
            const b = localStorage.getItem(btoa(`markup/${item?.priceListGuid}`)) || vm.markupText;

            const diff = Math.round(+(a * (b / 100)).toFixed(2));
            const sellingPrice = Math.round(+(a + diff).toFixed(2));

            if (diff) {
              let vl = `<span class="green--text">(+${vm.$sum(diff)})</span> `;

              const refRtSlPrice = item.productReferentPriceRetailSalePrice;
              if (sellingPrice && refRtSlPrice && sellingPrice > refRtSlPrice) {
                return `${vm.$sum(vl)} <span class="red--text">${vm.$sum(sellingPrice)}</span>`;
              }
              return `${vm.$sum(vl)} ${vm.$sum(sellingPrice)}`;
            }

            return `${vm.$sum(sellingPrice)}`;
          },
          width: vm.$dxcs.price,
          _align: "right",
        },
        {
          text: vm.$t("table.productReferentPriceDate"),
          value: "productReferentPriceDate",
          width: vm.$dxcs.date,
          _filter: (v) => vm.$date(v),
        },
        {
          text: vm.$t("table.productReferentPriceWholeSalePrice"),
          value: "productReferentPriceWholeSalePrice",
          _filter: (v, item) => {
            const val = vm.$number(v);
            if (val == 0) return v;

            const a = vm.$number(item.amountDiscount) || vm.$number(item.amount);
            const b = item.productReferentPriceWholeSalePrice;
            const c = (b / a) * 100;
            const diff = +(c - 100).toFixed(2);
            const diffText = diff ? (diff < 0 ? `<span class="red--text">(${vm.$sum(diff)}%)</span>` : `<span class="green--text">(${vm.$sum(diff)}%)</span>`) : "";

            return `${diffText} ${vm.$sum(val)}`;
          },
          width: vm.$dxcs.price,
          _align: "right",
        },
        {
          text: vm.$t("table.productReferentPriceRetailSalePrice"),
          value: "productReferentPriceRetailSalePrice",
          _filter: (v, item) => {
            const val = vm.$number(v);
            if (val == 0) return v;

            const a = vm.$number(item.amountDiscount) || vm.$number(item.amount);
            const b = item.productReferentPriceRetailSalePrice;
            const c = (b / a) * 100;
            const diff = +(c - 100).toFixed(2);
            const diffText = diff ? (diff < 0 ? `<span class="red--text">(${vm.$sum(diff)}%)</span>` : `<span class="green--text">(${vm.$sum(diff)}%)</span>`) : "";

            return `${diffText} ${vm.$sum(val)}`;
          },
          width: vm.$dxcs.price,
          _align: "right",
        },
        {
          text: vm.tn("table.priceListDateTime"),
          value: "priceListDateTime",
          width: vm.$dxcs.date,
          _filter: (v) => vm.$date(v),
        },
        {
          text: vm.tn("table.load"),
          value: "load",
        },
        {
          text: vm.tn("table.promotion"),
          value: "promotion",
          _filter: (v, item) => {
            const a = item.isPriority ? vm.$number(item.amount) : vm.$number(item.amountDiscount) || vm.$number(item.amount);
            const b = item.isPriority ? vm.$number(item.discount) : vm.$number(vm.$parseNumbers(item.promotion));

            if (isNaN(b)) return item.promotion;

            const diff = Math.round((a * (b / 100)).toFixed(2));
            const promotion = Math.round((a - diff).toFixed(2));

            if (diff) {
              let formattedDiff = `<span class="green--text">(${vm.$number(b)}% / -${vm.$sum(diff)})</span> `;
              if (item.isPriority) {
                return `${item.description} \n ${vm.$sum(formattedDiff)} ${vm.$sum(promotion)}`;
              }
              return `${vm.$sum(formattedDiff)} ${vm.$sum(promotion)}`;
            }

            return item.promotion;
          },
        },
        {
          text: vm.tn("table.original_packaging"),
          value: "originalPackaging",
          _hide: true,
        },
        {
          text: vm.tn("table.base_price"),
          value: "basePrice",
          width: vm.$dxcs.price,
          _filter: (v) => vm.$sum(v),
          _hide: true,
        },
        {
          text: vm.tn("table.markup"),
          value: "markup",
          align: "right",
          width: 80,
          _hide: true,
        },
        {
          text: vm.tn("table.actions.count"),
          value: "actions",
          class: "disable-resize",
          sortable: false,
          _resize: false,
          width: 85,
        },
      ]
        .map((x) => {
          if (vm.tdSizes[x.value]) x.width = vm.tdSizes[x.value];
          return x;
        })
        .filter((e) => e.visible != false && !e._remove),

    isFilterBadge() {
      let values = Object.values(this.filterData);
      const letter = this.filterData.firstLetter;
      if (letter) values = values.filter((x) => x != letter);
      const filters = values.filter((x) => x === 0 || `${x || ""}`.length);
      return filters.length;
    },

    actionsList: (vm) => [
      {
        name: vm.tn("actions.show_price_btn"),
        action: (item) => {
          window.open(`/price-list/${item.priceListGuid}`, "_blank");
        },
      },
      {
        name: vm.tn("actions.info_btn"),
        action: (item) => {
          vm.$windowOpen("price-list-provider", item.priceListProviderGuid, item.priceListProviderName, 650, 800, "preview=1");
        },
      },
    ],

    getTableHeight() {
      let tbHeight = null;

      switch (this.isShowOrganizationStatistic) {
        case true:
          tbHeight = this.isHide ? this.tableHeight / 2.11 : this.tableHeight / 1.065;
          break;
        case false:
          tbHeight = this.tableHeight;
          break;
        case null:
          tbHeight = this.isHide ? this.tableHeight / 2.11 : this.tableHeight / 1.065;
          break;
      }
      return tbHeight;
    },
  },

  watch: {
    selected(newVal, oldVal) {
      if (newVal?.guid == oldVal?.guid) return;

      this.isShowItems = false;
      this.$refs.table2?.getEntries({ reset: true });
      this.$nextTick(() => {
        if (newVal?.guid) this.isShowItems = true;
        this.$refs.table3?.getEntries({ reset: true });
      });
    },
  },

  created() {
    const savedWidth = localStorage.getItem(`${this.ln}_left_side_width`);
    if (savedWidth) this.leftSideWidth = parseInt(savedWidth, 10);
  },

  mounted() {
    this.onResizableLeftSide();
  },

  methods: {
    ...mapActions("price-list-items", { getItemsByProductApi: "GET_API" }),

    getItemsByProduct(params) {
      params.options.productGuid = this.selected.guid;
      params.filterData = this.filterData;
      params.isCancel = !params.options.productGuid;
      params.options.isShowAnalog = this.isShowAnalog;
      params.options.isShowReplacement = this.isShowReplacement;
      return this.getItemsByProductApi(params);
    },

    onFilter(filterData) {
      this.filterData = { ...filterData, firstLetter: this.filterData.firstLetter };
      this.$setFilterData(filterData);
      this.$nextTick(() => this.$refs.table1?.getEntries({ reset: true }));
    },

    onSelectLetter(letter) {
      this.filterData = { ...this.filterData, firstLetter: letter };
      this.$setFilterData(this.filterData);
      this.$nextTick(() => this.$refs.table1?.getEntries({ reset: true }));
    },

    onTab() {
      const table1 = this.$refs.table1;
      const table2 = this.$refs.table2;
      if (table2?.checkFocused()) {
        table1.isSavePosition = true;
        table1.setFocusToItem();
      } else {
        table2.setFocusToItem();
      }
    },

    onShowAnalog() {
      this.isShowAnalog = !this.isShowAnalog;
      const isShow = this.isShowAnalog;
      localStorage.setItem("is-show-analog", isShow);
      this.$nextTick(() => this.$refs.table2?.getEntries({ reset: true }));
    },

    onShowReplacement() {
      this.isShowReplacement = !this.isShowReplacement;
      const isShow = this.isShowReplacement;
      localStorage.setItem("is-show-replacement", isShow);
      this.$nextTick(() => this.$refs.table2?.getEntries({ reset: true }));
    },

    getRowStyle(item) {
      if (item.productState == 2) return { backgroundColor: "#cdfad4" };
      else if (item.productState == 3) return { backgroundColor: "#f5facd" };
      else return { backgroundColor: "#fff" };
    },

    onShowOrganizationStatistic(value) {
      if (value == true) return (this.isShowOrganizationStatistic = true);
      else if (value == false) return (this.isShowOrganizationStatistic = false);
      else if (value == undefined) return (this.isShowOrganizationStatistic = null);
    },

    onResizableLeftSide() {
      const el = this.$refs.resizable;
      const resizer = el.querySelector(".resizer");
      resizer.addEventListener("mousedown", (e) => {
        e.preventDefault();
        window.addEventListener("mousemove", resize);
        window.addEventListener("mouseup", stopResize);
      });

      const resize = (e) => {
        const width = e.pageX - el.getBoundingClientRect().left;
        if (width > 100 && width < window.innerWidth * 0.5) {
          this.leftSideWidth = width;
        }
      };

      const stopResize = () => {
        window.removeEventListener("mousemove", resize);
        localStorage.setItem(`${this.ln}_left_side_width`, this.leftSideWidth);
      };
    },
  },
};
</script>

<template>
  <div>
    <TableFilter
      v-if="isFilter"
      :value="filterData"
      @close="isFilter = false"
      @apply="onFilter"
    />

    <v-container fluid>
      <div class="page-wrap">
        <div
          ref="resizable"
          class="left-side"
          :style="{ width: leftSideWidth + 'px' }"
        >
          <products-list
            ref="table1"
            :ln="ln + '.product_list'"
            :tableHeight="tableHeight - 51"
            :sortBy="sortBy"
            :filterData="filterData"
            @select="(v) => (selected = v)"
            @showOrganizationStatistic="onShowOrganizationStatistic"
          >
            <template #actions>
              <v-badge
                dot
                overlap
                color="error"
                :value="isFilterBadge"
              >
                <v-btn
                  color="primary"
                  min-width="40"
                  width="40"
                  height="40"
                  min-height="40"
                  outlined
                  @click="isFilter = true"
                  :title="tn('filter_btn')"
                >
                  <v-icon> mdi-filter </v-icon>
                </v-btn>
              </v-badge>

              <LettersFilterMenu
                :value="filterData.firstLetter"
                @input="onSelectLetter"
              />
            </template>
          </products-list>
          <div class="resizer" />
        </div>

        <div class="content-side">
          <price-list-items
            :GET_API="getItemsByProduct"
            :entries="entriesByProduct"
            @markup="(v) => (markupText = v)"
            @isMyMarkup="(v) => (isMyMarkup = v)"
            @select="(v) => (itemData = v)"
            :entriesCount="entriesCountByProduct"
            :tableHeaders="tableHeaders"
            :ln="ln"
            :sortBy="sortBy2"
            :tableHeight="getTableHeight"
            :uniqKey="uniqKey"
            :url="urlItemsByProduct"
            hideBackBtn
            disableAutofocus
            :actionsList="actionsList"
            @tab="onTab"
            ref="table2"
            :getRowStyle="getRowStyle"
            :v-resize-table="{ tableHeaders: tableHeaders, callback: onUpdateTdSizes }"
          >
            <template #title>
              <div class="d-flex align-center">
                <div
                  v-if="selected.guid"
                  class="text2line"
                >
                  <h4>{{ selected.name }} ({{ selected.brandName }})</h4>
                </div>
                <div class="py-6"></div>
              </div>
            </template>

            <template #actions-prepend="slotProps">
              <v-btn
                :color="isShowAnalog ? '#cdfad4' : 'primary'"
                min-width="40"
                width="40"
                height="40"
                min-height="40"
                :outlined="!isShowAnalog"
                elevation="0"
                @click="onShowAnalog"
                :disabled="slotProps.loading"
                :loading="slotProps.loading"
                :title="tn('show_analog_btn')"
                class="mr-2"
              >
                <v-icon :color="isShowAnalog ? 'primary' : ''"> А </v-icon>
              </v-btn>

              <v-btn
                :color="isShowReplacement ? '#f5facd' : 'primary'"
                min-width="40"
                width="40"
                height="40"
                min-height="40"
                :outlined="!isShowReplacement"
                elevation="0"
                @click="onShowReplacement"
                :disabled="slotProps.loading"
                :loading="slotProps.loading"
                :title="tn('show_replacement_btn')"
                class="mr-2"
              >
                <v-icon :color="isShowReplacement ? 'primary' : ''"> З </v-icon>
              </v-btn>
            </template>

            <template #productName="slotProps">
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <div
                    class="text-left px-1"
                    v-bind="attrs"
                    v-on="on"
                    v-text="slotProps.item.productName"
                  />
                </template>
                <span v-if="slotProps.item.productState == 1">{{ tn("original") }}</span>
                <span v-else-if="slotProps.item.productState == 2">{{ tn("analog") }}</span>
                <span v-else-if="slotProps.item.productState == 3">{{ tn("replacement") }}</span>
              </v-tooltip>
            </template>
          </price-list-items>

          <ShowPharmacyRequirements
            v-if="isShowItems && isShowOrganizationStatistic != false"
            ref="table3"
            :tableHeight="tableHeight / 2.44"
            :productGuid="selected?.guid"
            :amount="itemData?.amountDiscount || itemData?.amount"
            :uniqKey="ln"
            :isShowSearchBtn="false"
            @isHide="(e) => (isHide = e)"
          />
        </div>
      </div>
    </v-container>
  </div>
</template>

<style lang="scss" scoped>
.page-wrap {
  display: flex;
  height: 100%;

  .left-side {
    max-width: 50%;
    overflow: hidden;
    transition: all 0.2s;
    position: relative;
  }

  .resizer {
    width: 10px;
    height: 100%;
    background: transparent;
    position: absolute;
    right: 0;
    top: 0;
    cursor: ew-resize;
  }

  .content-side {
    flex: 3;
    overflow-y: auto;
    position: relative;

    padding-left: 10px;
    margin-left: -1px;
  }
}
</style>
