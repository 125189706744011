import Vue from "vue";
const vm = Vue.prototype;

import axios from "axios";
import router from "@/router";
const CancelToken = axios.CancelToken;
let cancelSource = null;
let cancelSource1 = null;
let selectionErrorCount = 0;

const url = "PriceListViewerProductInfo";
const url2 = "PriceListViewerProductSelection";

export const state = () => ({
  productInfoData: {},
  importItems: [],
  duplicateItems: {},
});

export const mutations = {
  SET_PRODUCT_INFO(state, payload) {
    state.productInfoData = {
      items: vm.$array(
        payload?.items.map((x, i) => {
          x._id = i + 1;
          return x;
        })
      ),
      total: vm.$number(payload?.totalCount),
    };
  },

  SET_IMPORT_ITEMS(state, payload = []) {
    state.importItems = vm.$array(payload);
  },

  SET_DUPLICATE_ITEMS(state, payload) {
    state.duplicateItems = {
      items: vm.$array(
        payload?.items.map((x, i) => {
          x._id = i + 1;
          return x;
        })
      ),
      total: vm.$number(payload?.totalCount),
    };
  },
};

export const actions = {
  async GET_API({ commit }, { options, isCancel }) {
    cancelSource?.cancel();
    cancelSource = CancelToken.source();

    commit("SET_PRODUCT_INFO");

    if (isCancel) return;

    const params = { ...options };
    const request = { progress: false, cancelToken: cancelSource.token };
    const res = await vm.$axios.$get(`${url}/List`, params, request);
    if (res?.succeeded) commit("SET_PRODUCT_INFO", res?.data);
  },

  async GET_DUPLICATE_ITEMS_API({ commit }, { options, isCancel }) {
    cancelSource1?.cancel();
    cancelSource1 = CancelToken.source();

    commit("SET_DUPLICATE_ITEMS");

    if (isCancel) return;

    const params = { ...options };
    const request = { progress: false, cancelToken: cancelSource1.token };
    const res = await vm.$axios.$get(`${url}/CheckDuplicates`, params, request);
    if (res?.succeeded) commit("SET_DUPLICATE_ITEMS", res?.data);
  },

  async POST_API(ctx, payload) {
    const res = await vm.$axios.$post(url, payload);
    return res?.succeeded;
  },

  async START_AUTO_SELECTION_API(ctx, payload) {
    const res = await vm.$axios.$post(url, payload);
    return res?.succeeded;
  },

  async DELETE_API(ctx, payload) {
    const res = await vm.$axios.$delete(url + `/${payload.guid}`);
    return res?.succeeded;
  },

  async DELETE_ALL_API() {
    const res = await vm.$axios.$delete(url + `/all`);
    return res?.succeeded;
  },

  async DELETE_DUPLICATES_API() {
    const res = await vm.$axios.$delete(url + `/Duplicates`);
    return res?.succeeded;
  },

  async START_SELECTION_AUTO(ctx, payload) {
    const res = await vm.$axios.$post(`${url2}/Start`, payload);
    if (res?.succeeded) {
      const status = await checkSelectionCompleteAsync(res.data);
      if (status) {
        router.push(`/selection/auto/result/${res.data}/product`);
      }
    }
    return res?.succeeded;
  },

  async EXPORT_EXCEL() {
    const name = ` Авто подбор`;
    await vm.$axios.$postResFile(url + "/Export", {
      name: name.replace(/['".]/g, ""),
    });
  },

  async CHECK_IMPORT() {
    const res = await vm.$axios.$get(`PriceListViewerProductImport/CheckImport`);
    return res?.succeeded ? res.data : null;
  },

  SET_IMPORT_ITEMS({ commit }, payload) {
    commit("SET_IMPORT_ITEMS", payload);
  },

  async IMPORT_ITEMS_API({ state }, { fields }) {
    const lineItems = correctItems(state.importItems, fields);
    let status = false;
    let data;
    if (lineItems?.length) {
      const res = await vm.$axios.$post("PriceListViewerProductImport", {
        lineItems,
      });
      status = res?.succeeded;
      data = res?.data;
    }

    return { status, count: lineItems?.length, data };
  },

  async ROUND_STOCK_NEED_QTY_API(ctx, payload) {
    const params = {
      lessThanOneQuantity: payload.less1,
      greaterThanOneQuantity: payload.large1,
    };

    const res = await vm.$axios.$post(`${url}/RoundStockNeedQuantity`, params);
    return res?.succeeded;
  },

  async CHECK_EXISTS_API() {
    const res = await vm.$axios.$post(`${url}/CheckExists`);
    return res?.succeeded ? res.data : null;
  },
};

export const getters = {
  GET: (state) => state.productInfoData.items,
  GET_COUNT: (state) => state.productInfoData.total,
  GET_IMPORT_ITEMS: (state) => state.importItems,
  GET_DUPLICATE_ITEMS: (state) => state.duplicateItems.items,
  GET_DUPLICATE_ITEMS_COUNT: (state) => state.duplicateItems.total,
};

function correctItems(lineItems, fields) {
  const lifs = [];
  for (const key in fields) {
    if (trim(fields[key])) {
      lifs.push({ key, param: fields[key] });
    }
  }

  return lineItems
    .map((lineItem) => {
      const params = {};

      lifs.forEach((li) => (params[li.param] = trim(lineItem[li.key])));

      if (params.nm) {
        const nms = `${params.nm}`.split("\\");
        params.manufacturerName = nms.length > 1 ? nms.pop() : "";
        params.productName = nms.join();
        delete params.nm;
      }

      // fix decimals
      Object.keys(params)
        .filter((_key) => _key != "productName" && _key != "manufacturerName")
        .forEach((_dkey) => (params[_dkey] = fixDecimal(params[_dkey])));
      return params;
    })
    .filter((x) => trim(x.productName) && trim(x.manufacturerName));
}

function fixDecimal(string) {
  let str = `${string || ""}`;

  if (str.includes(".")) {
    // Agar . bo'lsa u holda hamma , olib tashlash kerek
    str = str.replace(/,/g, "");
  } else {
    // Agar nuqta bo'lmasa va bir dona , bo'lsa u holda , -> .
    if ((str.match(/,/g) || []).length == 1) {
      str = str.replace(/,/g, ".");
    }
  }

  str = str.replace(/[^,.0-9]/g, "");

  if (str == "0") return null;
  return vm.$number(str) || null;
}

function trim(str) {
  return `${str || ""}`.trim();
}

async function checkSelectionCompleteAsync(guid) {
  return new Promise((resolve) => {
    checkSelectionComplete(guid, (e) => resolve(e));
  });
}

async function checkSelectionComplete(guid, callback) {
  const res = await vm.$axios.$get(`${url2}/${guid}/Check`);

  if (res?.succeeded) {
    selectionErrorCount = 0;
    if (res.data) return callback(true);
  } else {
    selectionErrorCount++;
  }

  if (selectionErrorCount <= 5) {
    setTimeout(() => checkSelectionComplete(guid, callback), 5000);
  } else {
    return callback(false);
  }
}
