import Vue from "vue";
const vm = Vue.prototype;

import axios from "axios";
const CancelToken = axios.CancelToken;
let cancelSource = {};

const checkId = (x, y) => x && x.id == y?.id;

let isSending = false;
let disableClose = false;

export const state = () => ({
  priceListItemsData: {},
  pendingSendQty: [],
  sendingItem: null,
});

export const mutations = {
  SET_PRICE_LIST_ITEMS(state, { url, data }) {
    const obj = { ...state.priceListItemsData };

    obj[url] = {
      items: vm.$array(data?.items).map((x, i) => {
        x._id = i + 1;
        return x;
      }),
      total: vm.$number(data?.totalCount),
      totals: {
        totalProviderCount: vm.$number(data?.totalProviderCount),
        totalAmount: vm.$number(data?.totalAmount),
        totalAmountDiscount: vm.$number(data?.totalAmountDiscount),
        totalItemsCount: vm.$number(data?.totalItemsCount),
        totalQuantity: vm.$number(data?.totalQuantity),
      },
    };

    state.priceListItemsData = obj;
  },

  REPLACE_PRICE_LIST_ITEM(state, payload) {
    const obj = { ...state.priceListItemsData };

    Object.keys(obj).forEach((url) => {
      vm.$array(obj[url]?.items).map((x) => {
        if (checkId(x, payload)) {
          x.basketLineItemGuid = payload.basketLineItemGuid;
          x.basketQuantity = payload.basketQuantity;
          x.isAlert = payload.isAlert;
          x.isFavorite = payload.isFavorite;
        }
        return x;
      });
    });

    state.priceListItemsData = obj;
  },

  SET_ITEM_QTY(state, payload) {
    if (!payload) return;

    const old = state.pendingSendQty.find((x) => checkId(x, payload));

    const newItem = {
      id: payload.id,
      priceListLineItemGuid: payload.priceListLineItemGuid,
      priceListLineItemPriceGuid: payload.priceListLineItemPriceGuid,
      isAddToRefusedList: payload.isAddToRefusedList,
      quantity: payload.quantity,
    };

    if (old) {
      state.pendingSendQty = state.pendingSendQty.map((x) => {
        if (checkId(x, payload)) return newItem;
        return x;
      });
    } else {
      if (payload.isFirst) {
        state.pendingSendQty.unshift(newItem);
      } else {
        state.pendingSendQty.push(newItem);
      }
    }
  },

  SET_SENDING_ITEM(state, payload) {
    if (payload) {
      state.sendingItem = payload;
      state.pendingSendQty = state.pendingSendQty.filter((x) => !checkId(x, payload));
    } else {
      state.sendingItem = null;
    }
  },
};

export const actions = {
  async GET_API({ commit }, { options, isCancel, filterData }) {
    const url = options.url;
    let url1 = url;

    delete options.url;

    cancelSource[url]?.cancel();
    cancelSource[url] = CancelToken.source();

    commit("SET_PRICE_LIST_ITEMS", { url });
    if (isCancel) return;

    const params = { ...options, ...filterData };

    let newParams = {};
    Object.keys(params).forEach((x) => {
      if (params[x] != undefined && params[x] != null) newParams[x] = params[x];
    });

    const request = { progress: false, cancelToken: cancelSource[url].token };

    const res = await vm.$axios.$post(url1, newParams, request);
    if (res?.succeeded) commit("SET_PRICE_LIST_ITEMS", { data: res?.data, url });
  },

  async SEND_QTY({ state, dispatch, commit }) {
    if (isSending) return;
    isSending = true;

    const item = vm.$firstArrayItem(state.pendingSendQty);
    if (!item) {
      window.onbeforeunload = null;
      disableClose = false;
      isSending = false;
      return;
    }

    if (!disableClose) {
      disableClose = true;
      window.onbeforeunload = () => "";
    }

    commit("SET_SENDING_ITEM", item);

    const res = await vm.$axios.$post("basket", item);

    if (res?.data) {
      commit("SET_SENDING_ITEM");

      item.basketLineItemGuid = res.data;
      item.basketQuantity = item.quantity;

      if (res.data == "00000000-0000-0000-0000-000000000000") {
        item.basketLineItemGuid = null;
        item.basketQuantity = null;
      }

      commit("REPLACE_PRICE_LIST_ITEM", item);
      isSending = false;
      vm.$nextTick(() => dispatch("SEND_QTY"));
    } else {
      isSending = false;
      item.isFirst = true;
      commit("SET_ITEM_QTY", item);
    }
  },

  SET_ITEM_QTY({ commit, dispatch }, payload) {
    commit("SET_ITEM_QTY", payload);
    vm.$nextTick(() => dispatch("SEND_QTY"));
  },

  async FAVORITE_API({ commit }, payload) {
    let status = false;
    const url = `PriceListViewerFavoriteProduct/${payload.linkedProductGuid}`;
    payload.isFavorite = !payload.isFavorite;
    if (payload.isFavorite) status = (await vm.$axios.$post(url))?.succeeded;
    else status = (await vm.$axios.$delete(url))?.succeeded;

    if (status) commit("REPLACE_PRICE_LIST_ITEM", payload);
  },

  async ALERT_API({ commit }, payload) {
    let status = false;
    const url = `PriceListViewerProductAlert/${payload.linkedProductGuid}`;
    payload.isAlert = !payload.isAlert;
    if (payload.isAlert) status = (await vm.$axios.$post(url))?.succeeded;
    else status = (await vm.$axios.$delete(url))?.succeeded;

    if (status) commit("REPLACE_PRICE_LIST_ITEM", payload);
  },
};

export const getters = {
  GET: (state) => (url) => vm.$array(state.priceListItemsData[url]?.items),
  GET_COUNT: (state) => (url) => state.priceListItemsData[url]?.total || 0,
  GET_TOTALS: (state) => (url) => state.priceListItemsData[url]?.totals || {},
  GET_PENDING_ITEM: (state) => (item) => state.pendingSendQty.find((x) => checkId(x, item)),
  GET_SENDING_ITEM: (state) => (item) => checkId(state.sendingItem || {}, item),
};
