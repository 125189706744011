import Vue from "vue";
const vm = Vue.prototype;

import axios from "axios";
const CancelToken = axios.CancelToken;
let cancelSource = null;

export const state = () => ({
  itemsData: {},
});

export const mutations = {
  SET_ITEMS(state, payload) {
    state.itemsData = {
      items: vm.$array(
        payload?.items.map((x, i) => {
          x._id = i + 1;
          return x;
        })
      ),
      total: vm.$number(payload?.totalCount),
    };
  },
};

export const actions = {
  async GET_API({ commit }, { options, isCancel, filterData }) {
    cancelSource?.cancel();
    cancelSource = CancelToken.source();

    commit("SET_ITEMS");
    if (isCancel) return;
    const request = { progress: false, cancelToken: cancelSource.token };
    const params1 = { ...options, ...filterData };

    let params = {};
    for (const iterator in params1) {
      if (params1[iterator] != null || params1[iterator] != undefined) {
        params[iterator] = params1[iterator];
      }
    }

    const res = await vm.$axios.$post("Basket/RefusedList", params, request);
    if (res?.succeeded) commit("SET_ITEMS", res?.data);
  },
};

export const getters = {
  GET: (state) => state.itemsData.items,
  GET_COUNT: (state) => state.itemsData.total,
};
