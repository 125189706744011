<script>
import { mapActions, mapGetters } from "vuex";
import tnVue from "@/mixins/tn.vue";
import TableResizeVue from "@/mixins/table-resize.vue";
import TableNavFunctionsVue from "@/mixins/table-nav-functions.vue";
import TableFilter from "@/components/modals/price-list-compare/table-filter.vue";

import ConfirmDialog from "@/components/modals/confirm-dialog.vue";
import RefusedList from "./components/refused-list.vue";
import PriceListItems from "@/components/price-list-items/price-list-items.vue";
import RefusedListItems from "./components/refused-list-items.vue";

const urlRefusedListItems = `Basket/RefusedList/Items`;
const urlItemsByProduct = `PriceListLineItem/AlternativeList`;

export default {
  components: {
    TableFilter,
    ConfirmDialog,
    RefusedList,
    RefusedListItems,
    PriceListItems,
  },

  data: (vm) => ({
    ln: "basket",
    uniqKey: "refused-list-items",
    uniqKey2: "price-list-compare",
    sortBy: "providerName",
    sortBy2: "amount",
    isDrawer: true,
    leftSideWidth: 454,
    selected2: {},
    selectedRows: vm.$browserStore.getSession("refusedSelectedRows") || [],

    isFilter: false,
    isShowMerge: false,
    isExistAlert: false,
    isAutoSelect: false,
    filterData: vm.$getFilterData() || {},

    urlRefusedListItems,
    urlItemsByProduct,

    isShowAnalog: null,
    isShowReplacement: null,

    isMyMarkup: false,
    markupText: null,
  }),

  mixins: [tnVue, TableResizeVue, TableNavFunctionsVue],

  computed: {
    ...mapGetters("basket-refused-items", ["GET_REFUSED", "GET_REFUSED_COUNT", "GET_REFUSED_TOTALS"]),
    ...mapGetters("price-list-items", ["GET", "GET_COUNT", "GET_TOTALS"]),

    entries: (vm) => vm.GET_REFUSED(urlRefusedListItems),
    entriesCount: (vm) => vm.GET_REFUSED_COUNT(urlRefusedListItems),
    entriesTotals: (vm) => vm.GET_REFUSED_TOTALS(urlRefusedListItems),

    entriesByProduct: (vm) => vm.GET(urlItemsByProduct),
    entriesCountByProduct: (vm) => vm.GET_COUNT(urlItemsByProduct),
    entriesTotalsByProduct: (vm) => vm.GET_TOTALS(urlItemsByProduct),

    size: (vm) => vm.$store.getters.GET_SIZE,
    tableHeight: (vm) => vm.size.height - 204,

    tableHeaders: (vm) =>
      [
        {
          text: "№",
          value: "number",
          width: 10,
          sortable: false,
          _resize: false,
          class: "disable-resize",
        },
        {
          text: vm.tn("refused_list_items.table.productName"),
          value: "productName",
          _required: true,
        },
        {
          text: vm.tn("refused_list_items.table.manufacturerName"),
          value: "manufacturerName",
          _required: true,
        },
        {
          text: vm.tn("refused_list_items.table.shelfLife"),
          value: "shelfLife",
          width: vm.$dxcs.date,
          _filter: (v) => vm.$date(v),
        },
        {
          text: vm.tn("refused_list_items.table.price"),
          value: "price",
          width: vm.$dxcs.price,
          _filter: (v) => vm.$sum(v),
          _align: "right",
        },
        {
          text: vm.tn("refused_list_items.table.total_amount"),
          value: "totalAmount",
          width: vm.$dxcs.totalAmount,
          _filter: (v) => vm.$sum(v),
        },
        {
          text: vm.tn("refused_list_items.table.load"),
          value: "load",
        },
        {
          text: vm.tn("table.actions.count"),
          value: "actions",
          class: "disable-resize",
          _resize: false,
          width: 85,
        },
      ].map((x) => {
        if (vm.tdSizes[x.value]) x.width = vm.tdSizes[x.value];
        return x;
      }),

    table2Headers: (vm) =>
      [
        {
          text: "№",
          value: "number",
          width: 10,
          sortable: false,
          _resize: false,
          class: "disable-resize",
        },
        {
          text: vm.tn("table.priceProvider"),
          value: "priceListProviderName",
          width: vm.$dxcs.priceListProviderName,
        },
        {
          text: vm.tn("table.productName"),
          value: "productName",
          width: vm.$dxcs.productName,
          _html: true,
          _slot: true,
        },
        {
          text: vm.tn("table.manufacturerName"),
          value: "manufacturerName",
          width: vm.$dxcs.manufacturer,
        },
        {
          text: vm.tn("table.shelfLife"),
          value: "shelfLife",
          width: vm.$dxcs.date,
          _filter: (v) => vm.$date(v),
        },
        {
          text: vm.tn("table.priceTypeName"),
          value: "priceTypeName",
          width: vm.$dxcs.priceTypeName,
        },
        {
          text: vm.tn("table.amount"),
          value: "amount",
          _filter: (v, item) => {
            const val = vm.$sum(v);
            if (!val) return;

            const a = vm.selected2.amountDiscount || vm.selected2.amount;
            const b = item.amount;
            const c = (b / a) * 100;
            const diff = +(c - 100).toFixed(2);
            if (diff) {
              let vl = `<span class="red--text">(${vm.$sum(diff)}%)</span>`;
              if (diff < 0) vl = `<span class="green--text">(${vm.$sum(diff)}%)</span>`;
              return `${vl} ${val}`;
            }

            return `${val}`;
          },
          _getColor: (v, item) => {
            const mxp = item.productReferentPriceWholeSalePrice;
            if (v && mxp && v > mxp) return "red";
          },
          width: vm.$dxcs.amount,
          _align: "right",
        },
        {
          text: vm.tn("table.discount_amount"),
          value: "amountDiscount",
          _filter: (v, item) => {
            const val = vm.$sum(v);
            if (!val) return;

            const a = vm.selected2.amountDiscount || vm.selected2.amount;
            const b = item.amountDiscount;
            const c = (b / a) * 100;
            const diff = +(c - 100).toFixed(2);
            if (diff) {
              let vl = `<span class="red--text">(${vm.$sum(diff)}%)</span>`;
              if (diff < 0) vl = `<span class="green--text">(${vm.$sum(diff)}%)</span>`;
              return `${vl} ${val}`;
            }

            return `${val}`;
          },
          width: vm.$dxcs.amount,
          _align: "right",
        },
        {
          text: vm.tn("table.my_selling_price"),
          value: "mySellingPrice",
          sortable: false,
          visible: vm.isMyMarkup,
          _filter: (v, item) => {
            const a = item.amountDiscount || item.amount;
            const b = localStorage.getItem(btoa(`markup/${item?.priceListGuid}`)) || vm.markupText;
            const diff = Math.round(+(a * (b / 100)).toFixed(2));
            const sellingPrice = Math.round(+(a + diff).toFixed(2));

            if (diff) {
              let vl = `<span class="green--text">(+${vm.$sum(diff)})</span> `;

              const refRtSlPrice = item.productReferentPriceRetailSalePrice;
              if (sellingPrice && refRtSlPrice && sellingPrice > refRtSlPrice) {
                return `${vm.$sum(vl)} <span class="red--text">${vm.$sum(sellingPrice)}</span>`;
              }
              return `${vm.$sum(vl)} ${vm.$sum(sellingPrice)}`;
            }

            return `${vm.$sum(sellingPrice)}`;
          },
          width: vm.$dxcs.price,
          _align: "right",
        },
        {
          text: vm.$t("table.productReferentPriceDate"),
          value: "productReferentPriceDate",
          width: vm.$dxcs.date,
          _filter: (v) => vm.$date(v),
        },
        {
          text: vm.$t("table.productReferentPriceWholeSalePrice"),
          value: "productReferentPriceWholeSalePrice",
          _filter: (v, item) => {
            const val = vm.$number(v);
            if (!val) {
              return vm.$sum(val);
            }

            const a = vm.$number(item.amountDiscount) || vm.$number(item.amount);
            const b = item.productReferentPriceWholeSalePrice;
            const c = (b / a) * 100;
            const diff = +(c - 100).toFixed(2);
            const diffText = diff ? (diff < 0 ? `<span class="red--text">(${vm.$sum(diff)}%)</span>` : `<span class="green--text">(${vm.$sum(diff)}%)</span>`) : "";

            return `${diffText} ${vm.$sum(val)}`;
          },
          width: vm.$dxcs.price,
          _align: "right",
        },
        {
          text: vm.$t("table.productReferentPriceRetailSalePrice"),
          value: "productReferentPriceRetailSalePrice",
          _filter: (v, item) => {
            const val = vm.$number(v);
            if (!val) {
              return vm.$sum(val);
            }

            const a = vm.$number(item.amountDiscount) || vm.$number(item.amount);
            const b = item.productReferentPriceRetailSalePrice;
            const c = (b / a) * 100;
            const diff = +(c - 100).toFixed(2);
            const diffText = diff ? (diff < 0 ? `<span class="red--text">(${vm.$sum(diff)}%)</span>` : `<span class="green--text">(${vm.$sum(diff)}%)</span>`) : "";

            return `${diffText} ${vm.$sum(val)}`;
          },
          width: vm.$dxcs.price,
          _align: "right",
        },
        {
          text: vm.tn("table.load"),
          value: "load",
        },
        {
          text: vm.tn("table.priceListDateTime"),
          value: "priceListDateTime",
          width: vm.$dxcs.date,
          _filter: (v) => vm.$date(v),
        },
        {
          text: vm.tn("table.actions.count"),
          value: "actions",
          class: "disable-resize",
          sortable: false,
          _resize: false,
          width: 85,
        },
      ]
        .map((x) => {
          if (vm.tdSizes2[x.value]) x.width = vm.tdSizes2[x.value];
          return x;
        })
        .filter((e) => e.visible != false),

    table2ActionsList: (vm) => [
      {
        name: vm.tn("actions.show_price_btn"),
        action: (item) => {
          window.open(`/price-list/${item.priceListGuid}`, "_blank");
        },
        icon: "mdi-information",
      },
      {
        name: vm.tn("actions.info_btn"),
        action: (item) => vm.$windowOpen("price-list-provider", item.priceListProviderGuid, item.priceListProviderName, 650, 800, "preview=1"),
        icon: "mdi-information",
      },
    ],

    isFilterBadge() {
      const values = Object.values(this.filterData);
      const filters = values.filter((x) => x === 0 || `${x || ""}`.length);
      return filters.length;
    },
  },

  watch: {
    selected2: {
      handler(newV, oldV) {
        if (newV?.priceListLineItemGuid !== oldV?.priceListLineItemGuid) {
          this.$refs.table3?.getEntries({ reset: true });
        }
      },
      deep: true,
    },

    selectedRows() {
      this.$refs.table2?.getEntries({ reset: true });
    },
  },

  created() {
    const savedWidth = localStorage.getItem(`${this.ln}_refused_left_side_width`);
    if (savedWidth) this.leftSideWidth = parseInt(savedWidth, 10);
  },

  mounted() {
    document.addEventListener("keydown", this.onDocumentKeyup);
    this.onResizableLeftSide();
  },

  destroyed() {
    document.removeEventListener("keydown", this.onDocumentKeyup);
  },

  methods: {
    ...mapActions("basket-refused-items", ["GET_REFUSED_API"]),
    ...mapActions("price-list-items", ["GET_API"]),
    ...mapActions("selection-auto", ["CHECK_EXISTS_API"]),

    getRefusedListItems(params) {
      const refusedGuids = this.selectedRows.map((x) => x.guid);
      params.options.refusedGuids = refusedGuids;
      params.filterData = this.filterData;

      if (this.isAutoSelect) params.options.isAutoSelect = this.isAutoSelect;
      return this.GET_REFUSED_API(params);
    },

    getItemsByProduct(params) {
      params.options.priceListLineItemGuid = this.selected2.priceListLineItemGuid;
      params.filterData = this.filterData;
      params.isCancel = !params.options.priceListLineItemGuid;
      params.options.isShowAnalog = this.isShowAnalog;
      params.options.isShowReplacement = this.isShowReplacement;

      return this.GET_API(params);
    },

    async onShowMerge() {
      this.$store.dispatch("SET_LOADING", true);

      this.isExistAlert = await this.CHECK_EXISTS_API();
      this.isShowMerge = true;

      this.$store.dispatch("SET_LOADING", false);
    },

    async onMerge({ close, setLoading }) {
      setLoading(true);
      this.isAutoSelect = true;
      await this.$refs.table2?.getEntries({ reset: true });
      this.isAutoSelect = false;

      const _selectedGuids = new Set(this.selectedRows.map((y) => y.guid));
      this.selectedRows = this.selectedRows.filter((x) => !_selectedGuids.has(x.guid));
      this.$browserStore.setSession("refusedSelectedRows", this.selectedRows);

      this.$nextTick(() => this.onRefresh());
      setLoading(false);
      close();
    },

    onDocumentKeyup(event) {
      const table1 = this.$refs.table1;
      const table2 = this.$refs.table2;

      if (event.code === "Space") {
        event.preventDefault();

        if (table2?.checkFocused()) {
          table1.isSavePosition = true;
          table1.setFocusToItem();
        } else if (table1?.checkFocused()) {
          table2.isSavePosition = true;
          table2.setFocusToItem();
        }
      }
    },

    onTab() {
      if (this.$refs.table3.checkFocused()) {
        this.$refs.table2.isSavePosition = true;
        this.$refs.table2.setFocusToItem();
      } else {
        this.$refs.table3.isSavePosition = true;
        this.$refs.table3.setFocusToItem();
      }
    },

    onResizableLeftSide() {
      const el = this.$refs.resizable;
      const resizer = el.querySelector(".resizer");
      resizer.addEventListener("mousedown", (e) => {
        e.preventDefault();
        window.addEventListener("mousemove", resize);
        window.addEventListener("mouseup", stopResize);
      });

      const resize = (e) => {
        const width = e.pageX - el.getBoundingClientRect().left;
        if (width > 100 && width < window.innerWidth * 0.5) {
          this.leftSideWidth = width;
        }
      };

      const stopResize = () => {
        window.removeEventListener("mousemove", resize);
        localStorage.setItem(`${this.ln}_refused_left_side_width`, this.leftSideWidth);
      };
    },

    onFilter(filterData) {
      this.filterData = filterData;
      this.$setFilterData(filterData);
      this.$refs.table2?.getEntries({ reset: true });
    },

    onShowAnalog() {
      this.isShowAnalog = !this.isShowAnalog;
      this.$refs.table3?.getEntries({ reset: true });
    },

    onShowReplacement() {
      this.isShowReplacement = !this.isShowReplacement;
      this.$refs.table3?.getEntries({ reset: true });
    },

    onRefresh() {
      this.$refs.table1?.getEntries({ reset: true });
      this.$refs.table2?.getEntries({ reset: true });
    },

    getRowStyle(item) {
      if (item.productState == 2) return { backgroundColor: "#cdfad4" };
      else if (item.productState == 3) return { backgroundColor: "#f5facd" };
      else return { backgroundColor: "#fff" };
    },

    onExit() {
      if (!this.$router.back()) this.$router.replace("/basket");
    },
  },
};
</script>

<template>
  <div>
    <v-container fluid>
      <ConfirmDialog
        :text="isExistAlert ? tn('refused_list_items.exist_alert') : tn('refused_list_items.send_to_auto_select_alert')"
        @close="isShowMerge = false"
        v-if="isShowMerge"
        @accept="onMerge"
      />

      <TableFilter
        v-if="isFilter"
        :value="filterData"
        @close="isFilter = false"
        @apply="onFilter"
      />

      <div class="d-flex align-center">
        <v-btn
          color="primary"
          min-width="40"
          width="40"
          height="40"
          min-height="40"
          class="mr-3"
          outlined
          @click="onExit"
          :title="tn('close')"
        >
          <v-icon> mdi-close </v-icon>
        </v-btn>

        <h3>
          {{ tn("basket_refused_title") }}
        </h3>
      </div>

      <div class="page-wrap">
        <div
          ref="resizable"
          :class="!isDrawer && 'left-side-closed'"
          class="left-side"
          :style="{ width: leftSideWidth + 'px' }"
        >
          <refused-list
            :isFocusable="isDrawer"
            ref="table1"
            class="left-side-list"
            :ln="ln + '.refused_list'"
            :tableHeight="tableHeight"
            :sortBy="sortBy"
            @selectedRows="(v) => (selectedRows = v)"
            @refresh="onRefresh"
          />
          <div class="resizer" />
        </div>

        <div
          class="content-side"
          :class="!isDrawer && 'left-side-closed'"
        >
          <div>
            <refused-list-items
              disableCountBold
              :GET_API="getRefusedListItems"
              :entries="entries"
              @select="(v) => (selected2 = v)"
              :entriesCount="entriesCount"
              :tableHeaders="tableHeaders"
              :ln="`${ln}.refused_list_items`"
              :tableHeight="tableHeight / 2.4"
              :uniqKey="uniqKey"
              :url="urlRefusedListItems"
              disableAutofocus
              disableTab
              ref="table2"
              isShowTotals
              :activeItem="selected"
              :v-resize-table="{ tableHeaders: tableHeaders, callback: onUpdateTdSizes }"
            >
              <template #title>
                <div class="d-flex align-center">
                  <v-btn
                    color="primary"
                    min-width="40"
                    width="40"
                    height="40"
                    min-height="40"
                    class="mr-3"
                    outlined
                    @click="isDrawer = !isDrawer"
                    :title="isDrawer ? tn('close') : tn('open')"
                  >
                    <v-icon v-if="isDrawer"> mdi-arrow-left </v-icon>
                    <v-icon v-else> mdi-arrow-right </v-icon>
                  </v-btn>
                  <div class="py-6"></div>

                  <div v-if="selectedRows.length == 1">
                    <h3>
                      {{ selectedRows[0].providerName }}
                    </h3>

                    <small>
                      <b>{{ tn("time_started") }}:</b>
                      {{ selectedRows[0].timeStarted | dateTime }};
                    </small>
                  </div>
                </div>
              </template>

              <template #actions>
                <v-btn
                  color="primary"
                  min-width="40"
                  width="40"
                  height="40"
                  min-height="40"
                  outlined
                  @click="onShowMerge"
                  :disabled="isLoading || selectedRows.length == 0"
                  :title="tn('refused_list_items.send_to_auto_select_btn')"
                  class="mr-2"
                >
                  <v-icon> mdi-source-branch </v-icon>
                </v-btn>
              </template>

              <template #body-append="bodyProps">
                <td
                  v-for="(header, i) in bodyProps.headers"
                  :key="i"
                >
                  <div
                    v-if="header.value == 'number'"
                    class="text-nowrap text-center"
                  >
                    {{ entriesCount | sum }}
                  </div>

                  <div
                    v-else-if="header.value == 'totalAmount'"
                    class="text-nowrap text-right"
                  >
                    {{ entriesTotals.totalAmount | sum }}
                  </div>

                  <div
                    v-else-if="header.value == 'actions'"
                    class="text-nowrap text-right"
                  >
                    {{ entriesTotals.totalQuantity | sum }}
                  </div>

                  <div v-else />
                </td>
              </template>
            </refused-list-items>

            <div class="mt-1" />

            <price-list-items
              :GET_API="getItemsByProduct"
              :entries="entriesByProduct"
              @markup="(v) => (markupText = v)"
              @isMyMarkup="(v) => (isMyMarkup = v)"
              :entriesCount="entriesCountByProduct"
              :tableHeaders="table2Headers"
              :ln="ln"
              :sortBy="sortBy2"
              :tableHeight="tableHeight / 2.15"
              :uniqKey="uniqKey2"
              :url="urlItemsByProduct"
              hideBackBtn
              disableAutofocus
              ref="table3"
              @tab="onTab"
              :activeItem="selected2"
              :actionsList="table2ActionsList"
              :sort="false"
              isShowTotals
              :getRowStyle="getRowStyle"
              :v-resize-table="{ tableHeaders: table2Headers, callback: onUpdateTdSizes2 }"
            >
              <template
                v-if="selected2?.productName"
                #title
              >
                <h3
                  style="text-wrap: nowrap; overflow-x: hidden; text-overflow: ellipsis"
                  :style="{ maxWidth: isDrawer ? '800px' : '1250px' }"
                >
                  {{ selected2.productName }}
                  ({{ selected2.manufacturerName }})
                </h3>
                <small>
                  <b>{{ tn("total_amount") }}:</b> {{ selected2.totalAmount | sum }}
                </small>
              </template>

              <template #actions-prepend="slotProps">
                <v-btn
                  :color="isShowAnalog ? '#cdfad4' : 'primary'"
                  min-width="40"
                  width="40"
                  height="40"
                  min-height="40"
                  :outlined="!isShowAnalog"
                  elevation="0"
                  @click="onShowAnalog"
                  :disabled="slotProps.loading"
                  :loading="slotProps.loading"
                  :title="tn('show_analog_btn')"
                  class="mr-2"
                >
                  <v-icon :color="isShowAnalog ? 'primary' : ''"> А </v-icon>
                </v-btn>

                <v-btn
                  :color="isShowReplacement ? '#f5facd' : 'primary'"
                  min-width="40"
                  width="40"
                  height="40"
                  min-height="40"
                  :outlined="!isShowReplacement"
                  elevation="0"
                  @click="onShowReplacement"
                  :disabled="slotProps.loading"
                  :loading="slotProps.loading"
                  :title="tn('show_replacement_btn')"
                >
                  <v-icon :color="isShowReplacement ? 'primary' : ''"> З </v-icon>
                </v-btn>
              </template>

              <template #productName="slotProps">
                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <div
                      class="text-left px-1"
                      v-bind="attrs"
                      v-on="on"
                      v-text="slotProps.item.productName"
                    />
                  </template>
                  <span v-if="slotProps.item.productState == 1">{{ tn("original") }}</span>
                  <span v-else-if="slotProps.item.productState == 2">{{ tn("analog") }}</span>
                  <span v-else-if="slotProps.item.productState == 3">{{ tn("replacement") }}</span>
                </v-tooltip>
              </template>

              <template #actions>
                <v-badge
                  class="mx-2"
                  dot
                  overlap
                  color="error"
                  :value="isFilterBadge"
                >
                  <v-btn
                    color="primary"
                    min-width="40"
                    width="40"
                    height="40"
                    min-height="40"
                    outlined
                    @click="isFilter = true"
                    :title="tn('filter_btn')"
                  >
                    <v-icon> mdi-filter </v-icon>
                  </v-btn>
                </v-badge>
              </template>

              <template #body-append="bodyProps">
                <td
                  v-for="(header, i) in bodyProps.headers"
                  :key="i"
                >
                  <div
                    v-if="header.value == 'number'"
                    class="text-nowrap text-center"
                  >
                    {{ entriesCountByProduct | sum }}
                  </div>

                  <div
                    v-else-if="header.value == 'amount'"
                    class="text-nowrap text-right"
                  >
                    {{ entriesTotalsByProduct.totalAmount | sum }}
                  </div>

                  <div
                    v-else-if="header.value == 'actions'"
                    class="text-nowrap text-right"
                  >
                    {{ entriesTotalsByProduct.totalQuantity | sum }}
                  </div>

                  <div v-else />
                </td>
              </template>
            </price-list-items>
          </div>
        </div>
      </div>
    </v-container>
  </div>
</template>

<style lang="scss" scoped>
.page-wrap {
  display: flex;
  height: 100%;

  .left-side {
    max-width: 50%;
    overflow: hidden;
    transition: all 0.2s;
    position: relative;

    &.left-side-closed {
      max-width: 0;

      .left-side-list {
        max-height: 50vh;
      }
    }
  }

  .resizer {
    width: 10px;
    height: 100%;
    background: transparent;
    position: absolute;
    right: 0;
    top: 0;
    cursor: ew-resize;
  }

  .content-side {
    flex: 3;
    overflow-y: auto;
    position: relative;

    padding-left: 10px;
    margin-left: -1px;

    &.left-side-closed {
      margin-left: -12px;
      padding-left: 12px;
    }
  }
}
</style>
