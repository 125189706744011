<script>
import { mapActions, mapGetters } from "vuex";

import tnVue from "@/mixins/tn.vue";
import AuthTemplate from "./components/auth-template.vue";
import Recaptcha from "./components/recaptcha.vue";
import ConfirmAuthSmsCode from "./components/confirm-auth-sms-code.vue";

export default {
  components: {
    AuthTemplate,
    Recaptcha,
    ConfirmAuthSmsCode,
  },

  data: () => ({
    isLoading: false,
    ln: "auth",

    formData: {
      phone: null,
      isSendCodeByTelegramBot: true,
      isRememberMe: true,
    },

    isRecaptcha: false,
    isSendedSms: false,
    from: null,
    telegramBotUser: null,
  }),

  mixins: [tnVue],

  computed: {
    ...mapGetters("auth", {
      user: "GET_USER",
    }),
  },

  created() {
    if (!this.user?.serverTime) return;

    const phone = this.$route.query.phone;
    if (phone?.length == 9 && this.$number(phone)) {
      this.formData.phone = phone;
    }

    const remember = this.$route.query.remember;
    if (remember?.length) this.formData.isRememberMe = remember == "true";
  },

  methods: {
    ...mapActions("auth", ["AUTH"]),

    async onLogin() {
      if (this.$refs.form.validate()) {
        this.isLoading = true;
        const params = { ...this.formData, phone: "998" + this.formData.phone };
        const res = await this.AUTH(params);
        const status = res?.status;

        if (res?.telegramBotUser) this.telegramBotUser = res.telegramBotUser;

        switch (status) {
          case 0: {
            let url = `/register?phone=${this.formData.phone}`;
            if (params.isRememberMe) url += "&remember=1";
            this.$router.replace(url);
            break;
          }
          case 1:
            this.isRecaptcha = true;
            break;
          case 2:
            this.isSendedSms = true;
            break;
        }
        this.isLoading = false;
      }
    },

    onOpen() {
      window.open("https://youtu.be/bnk3Q8aeSWI", "_blank");
    },
  },
};
</script>

<template>
  <div>
    <auth-template>
      <v-card outlined>
        <Recaptcha
          @next="
            () => {
              isRecaptcha = false;
              isSendedSms = true;
            }
          "
          :phone="'998' + formData.phone"
          v-if="isRecaptcha"
        />

        <ConfirmAuthSmsCode
          @change="isSendedSms = false"
          :phone="'998' + formData.phone"
          :isRememberMe="formData.isRememberMe"
          :telegramBotUser="telegramBotUser"
          v-else-if="isSendedSms"
        />

        <v-card-text v-else>
          <v-form
            :disabled="isLoading"
            ref="form"
            lazy-validation
            onSubmit="return false;"
          >
            <h2 class="black--text"
              >{{ tn("title") }}
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <v-icon
                    size="30"
                    color="success"
                    v-bind="attrs"
                    @click="onOpen"
                    v-on="on"
                  >
                    mdi-chat-question-outline
                  </v-icon>
                </template>
                <span> {{ $t("how_does_this_work") }} </span>
              </v-tooltip>
            </h2>
            <div class="pt-5" />
            <div class="black--text">{{ tn("form.phone") }}</div>
            <v-text-field
              outlined
              dense
              v-inputmask="$masks.phone"
              :rules="[$rules.phone]"
              autofocus
              v-model="formData.phone"
              @keyup.enter="onLogin"
            />

            <div class="d-flex">
              <v-checkbox
                @keyup.enter="onLogin"
                v-model="formData.isRememberMe"
                class="mt-0 pt-0"
                hide-details
                :label="tn('form.remember_check')"
              />
            </div>
            <div class="pt-2" />
            <div class="d-flex">
              <v-checkbox
                @keyup.enter="onLogin"
                v-model="formData.isSendCodeByTelegramBot"
                class="mt-0 pt-0"
                hide-details
                :label="tn('form.send_code_by_telegram_bot')"
              />
            </div>
          </v-form>

          <div class="pt-5" />
          <v-btn
            :loading="isLoading"
            @click="onLogin"
            elevation="0"
            width="100%"
            color="primary"
          >
            {{ tn("sign_in_btn") }}
          </v-btn>
        </v-card-text>
      </v-card>
    </auth-template>
  </div>
</template>
