<script>
import { mapActions, mapGetters } from "vuex";
import debounce from "debounce";
import tnVue from "@/mixins/tn.vue";

import TableFocusInput from "./table-focus-input.vue";
import TableResizeVue from "@/mixins/table-resize.vue";
import TablePagination from "@/mixins/table-pagination.vue";
import TableNavFunctionsVue from "@/mixins/table-nav-functions.vue";

import TableFilter from "@/components/modals/selection/table-filter.vue";
import LettersFilterMenu from "@/components/letters-filter-menu.vue";
import SelectedListFavoriteBtn from "./selected-list-favorite-btn.vue";
import SelectedListAlertBtn from "./selected-list-alert-btn.vue";
import SearchSuggestBox from "@/components/search-suggest-box.vue";

const wrapUniqKey = "selection-auto-list-wrap";

export default {
  components: {
    TableFocusInput,
    TableFilter,
    LettersFilterMenu,
    SelectedListFavoriteBtn,
    SelectedListAlertBtn,
    SearchSuggestBox,
  },

  props: {
    sortBy: String,
    tableHeight: Number,
    ln: String,
  },

  data: (vm) => ({
    isFilter: false,

    uniqKey: "selection-auto-list",
    filterData: vm.$getFilterData() || { isFavoriteProvider: null, isLinkedOnly: true },
    options: vm.$getSortLocal({ key: "selection-auto-list" }),
    isShowFavorite: undefined,
    isShowAlert: undefined,
    isShowOrganizationStatistic: undefined,
    isShowOrganizationStatisticReject: undefined,
    isInit: false,
    isShowQuantityStockNeedAndStock: false,
    isShowSuggests: null,

    isWrap: localStorage.getItem(wrapUniqKey) != "false",
  }),

  mixins: [tnVue, TableResizeVue, TablePagination, TableNavFunctionsVue],

  computed: {
    ...mapGetters("product", {
      entries: "GET",
      entriesCount: "GET_COUNT",
    }),

    tableHeaders: (vm) =>
      [
        {
          text: "#",
          value: "number",
          width: 10,
          sortable: false,
          align: "center",
        },
        {
          text: vm.tn("table.productName"),
          value: "name",
          width: vm.$dxcs.selectionName,
          _required: true,
        },
        {
          text: vm.tn("table.manufacturer"),
          value: "brandName",
          width: vm.$dxcs.selectionManufacturer,
        },
        {
          text: vm.tn("table.quantity_stock"),
          value: "quantityStock",
          _filter: (v) => vm.$sum(v),
          _align: "right",
          _visible: vm.isShowQuantityStockNeedAndStock,
          width: vm.$dxcs.selectionQtyStockNeedAndStock,
        },
        {
          text: vm.tn("table.qty_stockNeed"),
          value: "quantityStockNeed",
          _filter: (v) => vm.$sum(v),
          _align: "right",
          _visible: vm.isShowQuantityStockNeedAndStock,
          _resize: false,
        },
        {
          text: "",
          value: "_actions_btn",
          class: "disable-resize",
          sortable: false,
          width: 10,
          _resize: false,
        },
      ]
        .filter((e) => e._visible != false)
        .map((x) => {
          if (vm.tdSizes[x.value]) x.width = vm.tdSizes[x.value];
          return x;
        }),

    favoriteBtnTitle() {
      if (this.isShowFavorite) {
        return this.tn("favorite.show_not_favorite_btn");
      } else if (this.isShowFavorite == false) {
        return this.tn("favorite.show_all_btn");
      } else {
        return this.tn("favorite.show_favorite_btn");
      }
    },

    alertBtnTitle() {
      if (this.isShowAlert) {
        return this.tn("alert.show_not_alert_btn");
      } else if (this.isShowAlert == false) {
        return this.tn("alert.show_all_btn");
      } else {
        return this.tn("alert.show_alert_btn");
      }
    },

    organizationStatisticBtnTitle() {
      if (this.isShowOrganizationStatistic) {
        return this.tn("organization_statistic.show_not_organization_statistic_btn");
      } else if (this.isShowOrganizationStatistic == false) {
        return this.tn("organization_statistic.show_all_btn");
      } else {
        return this.tn("organization_statistic.show_organization_statistic_btn");
      }
    },

    organizationStatisticRejectBtnTitle() {
      if (this.isShowOrganizationStatisticReject) {
        return this.tn("organization_statistic_reject.show_not_organization_statistic_reject_btn");
      } else if (this.isShowOrganizationStatisticReject == false) {
        return this.tn("organization_statistic_reject.show_all_reject_btn");
      } else {
        return this.tn("organization_statistic_reject.show_organization_statistic_reject_btn");
      }
    },

    isFilterBadge() {
      let values = Object.values(this.filterData);
      const letter = this.filterData.firstLetter;
      if (letter) values = values.filter((x) => x != letter);
      const filters = values.filter((x) => x === 0 || `${x || ""}`.length);
      return filters.length;
    },
  },

  watch: {
    selected(v = {}) {
      this.$emit("select", { ...v });
    },

    searchText() {
      this.onSearch();
    },

    isShowOrganizationStatistic(v) {
      if (v == undefined) {
        this.$emit("showOrganizationStatistic", undefined);
      } else {
        this.$emit("showOrganizationStatistic", v);
      }
    },
  },

  async created() {
    this.searchText = this.$route.query.search;

    this.$store.dispatch("SET_LOADING", true);
    const status = (await this.$axios.$get("PriceListViewerProductStatisticInfoStatus"))?.data;
    if (status) this.isShowQuantityStockNeedAndStock = status;
    this.isInit = true;
    this.$store.dispatch("SET_LOADING", false);
  },

  methods: {
    ...mapActions("product", { getEntriesApi: "GET_API" }),
    ...mapActions("selection-auto", { addApi: "POST_API" }),

    async getEntries(payload) {
      if (payload?.reset) this.options.page = 1;

      let loaders = { ...this.loaders };
      const loadKey = this.$uuid();
      loaders[loadKey] = true;
      this.loaders = loaders;

      this.$setSortLocal({ key: "selection-auto-list", options: this.options });

      const options = { ...this.options };

      const params = {
        options: {
          limit: options.itemsPerPage,
          offset: options.itemsPerPage * (options.page - 1) > 0 ? options.itemsPerPage * (options.page - 1) : 0,
          sortDirection: this.$getSort(this.options.sortDesc, true),
          sortBy: this.$getSort(this.options.sortBy),
          search: this.searchText,
          favorite: this.isShowFavorite,
          isAlert: this.isShowAlert,
          showByPriceListViewerOrganizationStatistic: this.isShowOrganizationStatistic,
          showByPriceListViewerProductRejects: this.isShowOrganizationStatisticReject,
          ...this.filterData,
        },
      };

      if (this.isShowFavorite == undefined) delete params.options.favorite;
      if (this.isShowAlert == undefined) delete params.options.isAlert;
      if (this.isShowOrganizationStatistic == undefined) delete params.options.showByPriceListViewerOrganizationStatistic;
      if (this.isShowOrganizationStatisticReject == undefined) delete params.options.showByPriceListViewerProductRejects;

      // if (`${this.searchText || ""}`.length < 2) {
      //   params.isCancel = true;
      // }

      params.filterData = {};

      await this.getEntriesApi(params);

      if (document.activeElement?.id != this.ln && !this.isShowSuggests) this.setFocusToItem();

      loaders = { ...this.loaders };
      delete loaders[loadKey];
      this.loaders = loaders;
    },

    checkFocused() {
      return document.activeElement.className.includes(this.uniqKey);
    },

    onSearch: debounce(function () {
      this.getEntries({ reset: true });
    }, 200),

    setFocusToTable() {
      this.isSavePosition = true;
      this.$nextTick(() => this.setFocusToItem());
    },

    onSelect({ item }) {
      this.selected = { ...item };
      this.$setInputFocus(item._id, this.uniqKey);
    },

    async onAdd({ item }) {
      this.$store.dispatch("SET_LOADING", true);
      const params = { productGuid: item.guid };
      await this.addApi(params);
      this.$store.dispatch("SET_LOADING", false);
      this.$emit("refresh");
    },

    onFilter(filterData) {
      this.filterData = filterData;
      this.$setFilterData(filterData);
      this.getEntries({ reset: true });
    },

    onSelectLetter(letter) {
      this.filterData = { ...this.filterData, firstLetter: letter };
      this.$setFilterData(this.filterData);
      this.getEntries({ reset: true });
    },

    onShowFavorite() {
      if (this.isShowFavorite) this.isShowFavorite = false;
      else if (this.isShowFavorite == false) this.isShowFavorite = undefined;
      else this.isShowFavorite = true;
      this.getEntries({ reset: true });
    },

    onShowAlert() {
      if (this.isShowAlert) this.isShowAlert = false;
      else if (this.isShowAlert == false) this.isShowAlert = undefined;
      else this.isShowAlert = true;
      this.getEntries({ reset: true });
    },

    onShowOrganizationStatistic() {
      if (this.isShowOrganizationStatistic) this.isShowOrganizationStatistic = false;
      else if (this.isShowOrganizationStatistic == false) this.isShowOrganizationStatistic = undefined;
      else this.isShowOrganizationStatistic = true;
      this.$nextTick(() => this.getEntries({ reset: true }));
    },

    onShowOrganizationStatisticReject() {
      if (this.isShowOrganizationStatisticReject) this.isShowOrganizationStatisticReject = false;
      else if (this.isShowOrganizationStatisticReject == false) this.isShowOrganizationStatisticReject = undefined;
      else this.isShowOrganizationStatisticReject = true;
      this.$nextTick(() => this.getEntries({ reset: true }));
    },

    onWrap() {
      this.isWrap = !this.isWrap;
      const isWrap = this.isWrap;
      localStorage.setItem(wrapUniqKey, isWrap);
    },

    onKeyupDown() {
      if (this.isLoading) return;
      this.setFocusToItem();
    },

    getRowStyle(item) {
      if (item.promotionType == 0) return { backgroundColor: "#0ff0fc59" };
      else return { backgroundColor: "#fff" };
    },

    onOpen() {
      window.open("https://youtu.be/ZWiu5J3ihqI", "_blank");
    },
  },
};
</script>

<template>
  <div>
    <TableFilter
      :ln="ln + '.filter'"
      v-if="isFilter"
      :value="filterData"
      @close="isFilter = false"
      @apply="onFilter"
    />

    <div class="d-flex align-center mt-1">
      <v-btn
        color="primary"
        min-width="40"
        width="40"
        height="40"
        min-height="40"
        outlined
        @click="getEntries"
        :disabled="isLoading"
        :loading="isLoading"
        :title="tn('refresh_btn')"
      >
        <v-icon> mdi-sync </v-icon>
      </v-btn>

      <div class="px-1" />

      <v-badge
        dot
        overlap
        color="error"
        :value="isFilterBadge"
      >
        <v-btn
          color="primary"
          min-width="40"
          width="40"
          height="40"
          min-height="40"
          outlined
          @click="isFilter = true"
          :title="tn('filter_btn')"
        >
          <v-icon> mdi-filter </v-icon>
        </v-btn>
      </v-badge>

      <LettersFilterMenu
        :value="filterData.firstLetter"
        @input="onSelectLetter"
      />

      <div class="px-1" />

      <v-btn
        color="primary"
        min-width="40"
        width="40"
        height="40"
        min-height="40"
        outlined
        :loading="isLoading"
        :title="favoriteBtnTitle"
        @click="onShowFavorite"
      >
        <v-icon v-if="isShowFavorite"> mdi-star </v-icon>
        <v-icon v-else-if="isShowFavorite == undefined"> mdi-star-half-full </v-icon>
        <v-icon v-else> mdi-star-outline </v-icon>
      </v-btn>

      <div class="px-1" />

      <v-btn
        color="primary"
        min-width="40"
        width="40"
        height="40"
        min-height="40"
        outlined
        :loading="isLoading"
        :title="alertBtnTitle"
        @click="onShowAlert"
      >
        <v-icon v-if="isShowAlert"> mdi-bell </v-icon>
        <img
          v-else-if="isShowAlert == undefined"
          src="@/assets/svg/mdi-bell-outline.svg"
          alt=""
        />
        <v-icon v-else> mdi-bell-outline </v-icon>
      </v-btn>

      <div class="mx-1" />

      <v-btn
        color="primary"
        min-width="40"
        width="40"
        height="40"
        min-height="40"
        outlined
        :loading="isLoading"
        :title="organizationStatisticBtnTitle"
        @click="onShowOrganizationStatistic"
      >
        <img
          v-if="isShowOrganizationStatistic"
          src="@/assets/svg/potrebnost.svg"
          alt=""
        />
        <img
          v-else-if="isShowOrganizationStatistic == undefined"
          src="@/assets/svg/potrebnost-off.svg"
          alt=""
        />
        <img
          v-else
          src="@/assets/svg/potrebnost-outlined.svg"
          alt=""
        />
      </v-btn>

      <div class="px-1" />

      <v-btn
        color="primary"
        min-width="40"
        width="40"
        height="40"
        min-height="40"
        outlined
        :loading="isLoading"
        :title="organizationStatisticRejectBtnTitle"
        @click="onShowOrganizationStatisticReject"
      >
        <img
          v-if="isShowOrganizationStatisticReject"
          src="@/assets/svg/reject.svg"
          alt=""
        />
        <img
          v-else-if="isShowOrganizationStatisticReject == undefined"
          src="@/assets/svg/reject-off.svg"
          alt=""
        />
        <img
          v-else
          src="@/assets/svg/reject-outlined.svg"
          alt=""
        />
      </v-btn>

      <div class="px-1" />

      <v-btn
        color="primary"
        min-width="40"
        width="40"
        height="40"
        min-height="40"
        outlined
        elevation="0"
        @click="onWrap"
        :disabled="isLoading"
        :loading="isLoading"
        :title="isWrap ? tn('wrap_btn') : tn('no_wrap_btn')"
        class="mr-2"
      >
        <v-icon v-if="!isWrap"> mdi-wrap </v-icon>
        <v-icon v-else> mdi-wrap-disabled </v-icon>
      </v-btn>

      <div class="px-1" />

      <v-tooltip bottom>
        <template #activator="{ on, attrs }">
          <v-icon
            size="30"
            color="success"
            v-bind="attrs"
            @click="onOpen"
            v-on="on"
          >
            mdi-chat-question-outline
          </v-icon>
        </template>
        <span> {{ $t("how_does_this_work") }} </span>
      </v-tooltip>
    </div>

    <SearchSuggestBox
      @keyup-down="onKeyupDown({ enter: true })"
      @show-suggests="(e) => (isShowSuggests = e)"
      :ln="ln"
      :searchBoxHotKey="113"
      v-model="searchText"
    />
    <slot />

    <div class="mt-1" />

    <v-data-table
      v-if="tableHeaders.length && tableHeight > 0 && isInit"
      dense
      key="guid"
      must-sort
      fixed-header
      class="table-footer-nowrap fixed-right"
      disable-pagination
      hide-default-footer
      :headers="tableHeaders"
      :height="tableHeight"
      :mobile-breakpoint="0"
      :loading="isLoading"
      :options.sync="options"
      :items="entries"
      :server-items-length="entriesCount"
      :sort-by="sortBy"
      v-resize-table="{ tableHeaders: tableHeaders, callback: onUpdateTdSizes }"
    >
      <template v-slot:item="{ item, headers, index }">
        <tr
          class="cursor-pointer"
          @click.stop="onSelect({ item })"
          @dblclick="onAdd({ item })"
          :class="{
            'tr-active': selected.guid == item.guid,
          }"
          :style="getRowStyle(item)"
        >
          <template v-for="head in headers">
            <td
              v-if="head.value == 'number'"
              :key="head.value"
              class="px-0 text-center"
              :style="{ width: tdSizes[head.value] || head.width, maxWidth: tdSizes[head.value] || head.width }"
            >
              <!-- <span
                class="text-nowrap"
                v-text="$sum((options.page - 1) * options.itemsPerPage + (index + 1))"
              /> -->
              <div class="d-flex align-center">
                <selected-list-favorite-btn
                  :ln="ln"
                  :item="item"
                />
                <selected-list-alert-btn
                  :ln="ln"
                  :item="item"
                />

                <v-btn
                  v-if="item.hasStatistics"
                  color="transparent"
                  icon
                  x-small
                >
                  <img
                    width="18"
                    height="18"
                    src="@/assets/svg/potrebnost.svg"
                    alt=""
                  />
                </v-btn>

                <v-btn
                  v-if="item.hasRejects"
                  color="primary"
                  icon
                  x-small
                >
                  <img
                    src="@/assets/svg/reject.svg"
                    alt=""
                    width="18"
                    height="18"
                  />
                </v-btn>
              </div>
            </td>

            <td
              v-else-if="head.value == 'name'"
              :key="head.value + 1"
              :style="{ width: head.width }"
            >
              <table-focus-input
                :ref="item.id"
                :tabindex="index + 1"
                :item="item"
                :uniKey="uniqKey"
                @enter="onAdd({ item })"
                @upKey="onUpKey({ item })"
                @downKey="onDownKey({ item })"
                @pageUp="onPageUp({ item })"
                @pageDown="onPageDown({ item })"
              />
              <div :style="{ minWidth: head.minWidth }">
                <v-menu
                  offset-y
                  :close-on-content-click="false"
                  rounded="lg"
                >
                  <template v-slot:activator="{ on: menu }">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on: tooltip }">
                        <v-icon
                          v-if="!!(item.promotionType == 0)"
                          v-on="{ ...tooltip, ...menu }"
                          color="green"
                        >
                          mdi-cash-plus
                        </v-icon>
                      </template>
                      <span>Oson Promotion</span>
                    </v-tooltip>
                  </template>

                  <v-list
                    v-if="item?.description"
                    max-width="400px"
                  >
                    <v-list-item>
                      <v-list-item-content>
                        <div v-html="item?.description" />
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-menu>

                {{ item[head.value] }}
              </div>
            </td>

            <td
              v-else-if="head.value == 'brandName'"
              :key="head.value + 2"
              :class="isWrap ? 'text-nowrap' : 'text-wrap'"
              :style="{ width: tdSizes[head.value] || head.width, maxWidth: tdSizes[head.value] || head.width }"
            >
              <div
                v-text="item.brandName"
                :style="{ minWidth: head.minWidth }"
              />
            </td>

            <td
              v-else-if="head.value == '_actions_btn'"
              :key="head.value + 3"
              class="px-1 text-center"
              @click="onAdd({ item })"
              v-ripple
              :title="tn('table.actions.add')"
              :style="{ width: head.width }"
            >
              <v-icon color="primary"> mdi-cart-arrow-right </v-icon>
            </td>

            <td
              v-else
              :key="head.value + 4"
              :style="{ width: tdSizes[head.value] || head.width, maxWidth: tdSizes[head.value] || head.width }"
            >
              <div
                :style="{ minWidth: head.minWidth, textAlign: head._align }"
                v-html="head._filter ? head._filter(item[head.value], item) : item[head.value]"
                :class="{ 'text-nowrap': head._wrap }"
              />
            </td>
          </template>
        </tr>
      </template>

      <template #footer>
        <TablePaginationButtons
          :loading="isLoading"
          :options="options"
          :lastPage="lastPage"
          :perPageInput="perPageInput"
          :pageInput="pageInput"
          :entriesCount="entriesCount"
          :onLastPage="onLastPage"
          :onChangePerPageInput="onChangePerPageInput"
          :onChangePageInput="onChangePageInput"
        />
      </template>
    </v-data-table>
  </div>
</template>

<style lang="scss" scoped>
/* Quill editor container */
::v-deep(.ql-editor) {
  font-family: Arial, sans-serif;
  font-size: 16px;
  line-height: 1.6;
  color: #333;
}

/* Bold, Italic, Underline, Strike */
::v-deep(.ql-bold) {
  font-weight: bold;
}

::v-deep(.ql-italic) {
  font-style: italic;
}

::v-deep(.ql-underline) {
  text-decoration: underline;
}

::v-deep(.ql-strike) {
  text-decoration: line-through;
}

/* Headers (h1 - h6) */
::v-deep(.ql-header-1) {
  font-size: 2em;
  font-weight: bold;
}

::v-deep(.ql-header-2) {
  font-size: 1.75em;
  font-weight: bold;
}

::v-deep(.ql-header-3) {
  font-size: 1.5em;
  font-weight: bold;
}

::v-deep(.ql-header-4) {
  font-size: 1.25em;
  font-weight: bold;
}

::v-deep(.ql-header-5) {
  font-size: 1.1em;
  font-weight: bold;
}

::v-deep(.ql-header-6) {
  font-size: 1em;
  font-weight: bold;
}

/* Lists (Ordered, Bullet) */
::v-deep(.ql-direction-rtl) {
  direction: rtl;
  text-align: right;
}

::v-deep(.ql-align-center) {
  text-align: center;
}

::v-deep(.ql-align-right) {
  text-align: right;
}

::v-deep(.ql-align-justify) {
  text-align: justify;
}

/* Indentation */
::v-deep(.ql-indent-1) {
  padding-left: 20px;
}

::v-deep(.ql-indent-2) {
  padding-left: 40px;
}

::v-deep(.ql-indent-3) {
  padding-left: 60px;
}

/* Font sizes */
::v-deep(.ql-size-small) {
  font-size: 0.75em;
}

::v-deep(.ql-size-large) {
  font-size: 1.5em;
}

::v-deep(.ql-size-huge) {
  font-size: 2.5em;
}

/* Colors */
::v-deep(.ql-color-red) {
  color: red;
}

::v-deep(.ql-color-blue) {
  color: blue;
}

::v-deep(.ql-color-green) {
  color: green;
}

/* Background Colors */
::v-deep(.ql-background-yellow) {
  background-color: yellow;
}

::v-deep(.ql-background-gray) {
  background-color: gray;
}

/* Font styles */
::v-deep(.ql-font-serif) {
  font-family: "Times New Roman", serif;
}

::v-deep(.ql-font-monospace) {
  font-family: "Courier New", monospace;
}

/* Blockquote */
::v-deep(blockquote) {
  background: #f9f9f9;
  border-left: 4px solid #ccc;
  padding: 10px;
  font-style: italic;
}

/* Code block */
::v-deep(.ql-code-block) {
  background: #f4f4f4;
  color: #c7254e;
  font-family: "Courier New", monospace;
  padding: 10px;
  border-radius: 5px;
}

/* Links */
::v-deep(a) {
  color: #007bff;
  text-decoration: underline;
}

/* Images */
::v-deep(img) {
  max-width: 100%;
  height: auto;
  border-radius: 5px;
}

/* Formulas */
::v-deep(.ql-formula) {
  font-family: "Arial", sans-serif;
  font-size: 1.2em;
  color: #000;
}
</style>
