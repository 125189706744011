var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.isShowDelete)?_c('ConfirmDialog',{attrs:{"text":_vm.tn('delete_alert'),"hideInfo":""},on:{"close":() => {
        _vm.isShowDelete = false;
        _vm.setFocusToTable();
      },"accept":_vm.onDelete}}):_vm._e(),_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"w-100"},[_vm._t("title")],2),_c('v-spacer'),_c('v-btn',{staticClass:"mr-2",attrs:{"color":"primary","min-width":"40","width":"40","height":"40","min-height":"40","outlined":"","disabled":_vm.isLoading,"loading":_vm.isLoading,"title":_vm.tn('refresh_btn')},on:{"click":_vm.getEntries}},[_c('v-icon',[_vm._v(" mdi-sync ")])],1),_vm._t("actions-prepend",null,{"loading":_vm.isLoading}),_vm._t("actions"),(_vm.isShowCog)?_c('table-cog',{attrs:{"headersData":_vm.tableHeaders,"cacheKey":_vm.uniqKey},on:{"apply":(v) => (_vm.filteredHeaders = _vm.$compareArray(_vm.tableHeaders, v, 'value'))}}):_vm._e(),_c('div',{staticClass:"mx-1"}),_c('div',{staticStyle:{"max-width":"300px","width":"100%","min-width":"200px"}},[_c('SearchSuggestBox',{attrs:{"ln":_vm.ln,"searchBoxHotKey":_vm.searchBoxHotKey},on:{"keyup-down":function($event){return _vm.onKeyupDown({ enter: true })},"show-suggests":(e) => (_vm.isShowSuggests = e)},model:{value:(_vm.searchText),callback:function ($$v) {_vm.searchText=$$v},expression:"searchText"}})],1)],2),_vm._t("default"),_c('div',{staticClass:"mt-1"}),(_vm.filteredHeaders.length && _vm.tableHeight > 0)?_c('v-data-table',{directives:[{name:"resize-table",rawName:"v-resize-table",value:(_vm.vResizeTable),expression:"vResizeTable"}],key:"id",staticClass:"fixed-right",attrs:{"dense":"","fixed-header":"","disable-pagination":"","hide-default-footer":"","headers":_vm.filteredHeaders,"height":_vm.tableHeight,"mobile-breakpoint":0,"loading":_vm.isLoading,"options":_vm.options,"items":_vm.entries,"server-items-length":_vm.entriesCount,"must-sort":_vm.mustSort,"sort-by":_vm.sortBy,"multi-sort":_vm.multiSort,"disable-sort":!_vm.sort},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item",fn:function({ item, headers, index }){return [_c('tr',{staticClass:"cursor-pointer",class:{
          'tr-active': _vm.selected.id == item.id,
          'tr-active-v2': _vm.activeItem.id == item.id,
          'tr-basket-count-bold': !_vm.disableCountBold && !!item.basketQuantity,
        },style:(_vm.getRowStyle(item)),on:{"click":function($event){$event.stopPropagation();return _vm.onSelect({ item })}}},[_vm._l((headers),function(head){return [(head.value == 'number')?_c('td',{key:head.value,staticClass:"px-0 text-center"},[_c('span',{staticClass:"text-nowrap",domProps:{"textContent":_vm._s(_vm.$sum((_vm.options.page - 1) * _vm.options.itemsPerPage + (index + 1)))}})]):(head._slot)?_c('td',{key:head.value + 1,staticClass:"px-0 text-center",style:({ width: head.width })},[_vm._t(head.value,null,{"item":item})],2):(head.value == 'shelfLife')?_c('td',{key:head.value + 2,style:({ ..._vm.getShelfStyle(item), width: head.width })},[_c('div',{staticClass:"text-nowrap",style:({
                textAlign: head._align,
                minWidth: head.minWidth,
                color: head._getColor?.(item[head.value], item),
              }),domProps:{"innerHTML":_vm._s(head._filter(item[head.value], item))}})]):(head._filter)?_c('td',{key:head.value + 3,style:({ ...head._getStyle?.(item[head.value], item), width: head.width })},[_c('div',{staticClass:"text-nowrap",style:({
                textAlign: head._align,
                minWidth: head.minWidth,
                color: head._getColor?.(item[head.value], item),
              }),domProps:{"innerHTML":_vm._s(head._filter(item[head.value], item))}})]):(head.value == 'actions')?_c('td',{key:head.value + 4,staticClass:"px-0",style:({ width: head.width })},[_c('TableCartInput',{ref:item.id,refInFor:true,attrs:{"disabled":_vm.disableCount,"itemsLength":_vm.entries.length,"tabindex":index + 1,"item":item,"uniKey":_vm.uniqKey,"tn":_vm.tn},on:{"upKey":function($event){return _vm.onUpKey({ item })},"downKey":function($event){return _vm.onDownKey({ item })},"pageUp":function($event){return _vm.onPageUp({ item })},"pageDown":function($event){return _vm.onPageDown({ item })},"delete":function($event){return _vm.onShowDelete({ item })}}})],1):_c('td',{key:head.value + 6,style:({ width: _vm.tdSizes[head.value] || head.width, maxWidth: _vm.tdSizes[head.value] || head.width })},[_c('div',{style:({ minWidth: head.minWidth }),domProps:{"textContent":_vm._s(item[head.value])}})])]})],2)]}},(_vm.isShowTotals && _vm.entriesCount > 1)?{key:"body.append",fn:function(){return [_c('tr',{staticClass:"sticky-table-footer"},[_vm._t("body-append",null,{"headers":_vm.filteredHeaders})],2)]},proxy:true}:null,{key:"footer",fn:function(){return [_c('TablePaginationButtons',{attrs:{"loading":_vm.isLoading,"options":_vm.options,"lastPage":_vm.lastPage,"perPageInput":_vm.perPageInput,"pageInput":_vm.pageInput,"entriesCount":_vm.entriesCount,"onLastPage":_vm.onLastPage,"onChangePerPageInput":_vm.onChangePerPageInput,"onChangePageInput":_vm.onChangePageInput}})]},proxy:true}],null,true)}):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }