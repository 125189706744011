<script>
import { mapActions } from "vuex";

import tnVue from "@/mixins/tn.vue";
import Timer from "@/components/timer.vue";

export default {
  components: { Timer },

  props: {
    phone: String,
    isRememberMe: Boolean,
    telegramBotUser: String,
  },

  data: () => ({
    isLoading: false,
    ln: "confirm_auth_token",

    formData: {},

    isTimeLeft: false,
    isReSendCode: false,
  }),

  mixins: [tnVue],

  methods: {
    ...mapActions("auth", ["CONFIRM_AUTH_TOKEN", "RE_SEND_SMS_CODE"]),

    async onConfirm() {
      if (this.$refs.form.validate()) {
        this.isLoading = true;
        const params = {
          ...this.formData,
          phone: this.phone,
          isRememberMe: this.isRememberMe,
        };

        await this.CONFIRM_AUTH_TOKEN(params);

        this.isLoading = false;
      }
    },

    async onResendCode() {
      if (this.isLoading) return;
      this.isLoading = true;

      const status = await this.RE_SEND_SMS_CODE({ phone: this.phone });
      if (status) {
        this.isTimeLeft = false;
        this.isReSendCode = true;
      }

      this.isLoading = false;
    },
  },
};
</script>

<template>
  <v-card-text>
    <h2 class="black--text">{{ tn("title") }}</h2>
    <div class="pt-5">
      <div
        v-if="telegramBotUser && !isReSendCode"
        v-html="tn('code_sent_telegram_bot', { 0: telegramBotUser })"
      />

      <div v-else>
        {{ tn("code_sent_number") }}
        <span class="text-nowrap">
          {{ phone | tel }}
        </span>
      </div>
    </div>
    <div class="pt-3" />
    <v-form
      :disabled="isLoading"
      ref="form"
      lazy-validation
      onSubmit="return false;"
    >
      <div class="black--text">{{ tn("form.sms") }}</div>
      <v-text-field
        outlined
        dense
        autofocus
        hide-details
        :rules="[$rules.number4Length]"
        v-inputmask="$masks.number4Length"
        v-model="formData.smsCode"
        @keyup.enter="onConfirm"
      />
    </v-form>
    <div class="pt-3" />

    <div v-if="isTimeLeft">
      <a @click="onResendCode"> {{ telegramBotUser ? tn("get_sms_code_btn") : tn("re_send_sms_code_btn") }} </a>
    </div>
    <div
      v-else
      class="d-flex align-center"
    >
      <div class="pt-1">{{ tn("also_request_sms_code") }} &nbsp;</div>
      <Timer
        class="title"
        show-total
        :deadline="180000"
        @timeEnd="isTimeLeft = true"
      />
    </div>

    <a @click="$emit('change')">
      {{ tn("restart_auth") }}
    </a>

    <div class="pt-3" />

    <v-btn
      @click="onConfirm"
      :loading="isLoading"
      elevation="0"
      width="100%"
      color="primary"
    >
      {{ tn("confirm_btn") }}
    </v-btn>
  </v-card-text>
</template>
