<script>
import ModalsTemplate from "@/components/modals-template.vue";
import tnVue from "@/mixins/tn.vue";
import DateBox from "@/components/fields/date-box.vue";

export default {
  components: {
    ModalsTemplate,
    DateBox,
  },

  props: { resultGuid: String },

  data: (vm) => ({
    ln: "selection_auto.result_optimal_variant",
    formData: {
      shelfLifeFrom: vm.$getCurrentDate(null, 3),
      shouldDeleteFoundItemsFromAutoSelect: false,
    },
    dialog: true,
    isLoading: false,
    optimalVariants: [],
    optimalVariant: null,
    isInit: false,

    isFocusedDate: false,
  }),

  mixins: [tnVue],

  watch: {
    dialog(v) {
      if (!v) setTimeout(() => this.$emit("close"), 100);
    },
  },

  created() {
    this.init();
  },

  methods: {
    async onApply() {
      if (this.$refs.form.validate()) {
        this.$emit("accept", {
          setLoading: (v) => (this.isLoading = v),
          close: () => (this.dialog = false),
          payload: {
            groupingType: this.optimalVariant,
            shelfLifeFrom: this.formData.shelfLifeFrom,
            shouldDeleteFoundItemsFromAutoSelect: this.formData.shouldDeleteFoundItemsFromAutoSelect,
          },
        });
      }
    },

    async init() {
      this.isLoading = true;
      const url = `PriceListViewerProductSelection/${this.resultGuid}/AddToBasketOptimalInfo?ShelfLifeFrom=${this.formData.shelfLifeFrom}`;
      const res = await this.$axios.$get(url);
      this.optimalVariants = this.$array(res?.data);
      this.isLoading = false;
    },

    onRefreshVariants() {
      if (!this.formData.shelfLifeFrom) return;
      this.init();
      this.isFocusedDate = false;
    },
  },
};
</script>

<template>
  <modals-template
    :width="600"
    v-model="dialog"
    :title="tn('title')"
    :persistent="false"
  >
    <v-form
      :disabled="isLoading"
      ref="form"
      lazy-validation
      onSubmit="return false;"
    >
      <div>{{ tn("form.shelfLifeFrom") }}</div>
      <div class="d-flex">
        <div class="d-flex">
          <div style="max-width: 200px">
            <date-box
              v-model="formData.shelfLifeFrom"
              @focus="isFocusedDate = true"
              clearable
              dense
              hide-details
              required
            />
          </div>
          <div class="mx-1" />
          <v-btn
            v-if="isFocusedDate"
            @click="onRefreshVariants"
            :title="tn('refresh_btn')"
            depressed
            min-width="36"
            width="36"
            min-height="36"
            height="36"
            color="transparent"
          >
            <v-icon color="primary"> mdi-sync </v-icon>
          </v-btn>
        </div>

        <v-spacer />

        <div style="width: 100%; max-width: 230px">
          <v-checkbox
            class="mt-1"
            v-model="formData.shouldDeleteFoundItemsFromAutoSelect"
            :label="tn('form.should_delete_found_items_from_auto_select')"
          />
        </div>
      </div>

      <v-radio-group
        :disabled="isFocusedDate"
        v-model="optimalVariant"
      >
        <div
          v-for="variant in optimalVariants"
          :key="variant.groupingType"
        >
          <v-radio
            class="mb-0"
            :value="variant.groupingType"
          >
            <template #label>
              <b>{{ variant.name }}</b>
            </template>
          </v-radio>
          <div class="pl-8 pb-2">
            <small>
              {{ tn("form.providerCount") }}:
              {{ variant.providerCount | sum }}
            </small>
            <br />
            <small>
              {{ tn("form.totalAmount") }}:
              {{ variant.totalAmount | sum }}
            </small>
            <br />
            <small>
              {{ tn("form.totalDiscountAmount") }}:
              {{ variant.totalDiscountAmount | sum }}
            </small>
          </div>
        </div>
      </v-radio-group>

      <div class="d-flex align-center justify-center">
        <v-btn
          height="45"
          :loading="isLoading"
          :disabled="optimalVariant == null"
          color="primary"
          @click="onApply"
          elevation="0"
          class="mt-5 mb-1 w-49"
        >
          {{ tn("apply_btn") }}
        </v-btn>
      </div>
    </v-form>
  </modals-template>
</template>
