import router from "@/router";

import Vue from "vue";
const vm = Vue.prototype;

const toString = (string) => `${string || string >= 0 ? string : ""}`;
const array = (array) => (Array.isArray(array) ? array : []);

const firstArrayItem = (items) => {
  const arr = array(items);
  return arr.length ? arr[0] : null;
};

const lastArrayItem = (items) => {
  const arr = array(items);

  if (arr.length == 1) {
    return arr[0];
  } else if (arr.length > 1) {
    return arr[arr.length - 1];
  } else return null;
};

const compareArray = (array1, array2, key, dtorder) => {
  if (!array(array1).length || !array(array2).length) return array(array1);
  let items = array(array1).filter((x) => array(array2).find((y) => x[key] == y[key]));
  if (!dtorder) {
    items = items
      .map((x) => {
        const y = array(array2).find((y) => x[key] == y[key]);
        if (y) x._order = y._order;
        return x;
      })
      .sort((a, b) => a._order - b._order);
  }
  return items;
};

const setInputFocus = (i, key) => {
  if (!key) return console.error("key not found to Focus");
  const input = document?.querySelector(`input.${key}[tabindex='${i}']`);
  if (!input) return null;
  input.focus();

  if (input.getBoundingClientRect().top < 175) input.scrollIntoView({ block: "center" });

  setTimeout(() => {
    if (input == document.activeElement) input.select();
  }, 100);
  return input;
};

const number = (num, def) => {
  const n = Number(num);
  return n > 0 ? n : def || 0;
};

const parseNumbers = (input) => {
  const regex = /^\d+(\.\d+)?$/;
  return regex.test(input) ? input : "";
};

const uuid = () => {
  return Date.now().toString(36) + Math.random().toString(36).substring(2);
};

const upperCaseFirst = (str) => {
  if (!str) return;
  return str.charAt(0).toUpperCase() + str.substring(1);
};

const getSort = (v, dir) => {
  const arr = array(v);
  if (!arr.length) return null;
  if (dir) return arr[0] == true ? "desc" : "asc";
  else return `"${upperCaseFirst(arr[0])}"`;
};

const copyJson = (v) => {
  try {
    let json = JSON.parse(JSON.stringify(v));
    return json;
  } catch (error) {
    return null;
  }
};

const hasProp = (v, key) => {
  return v?.[key];
};

const popupCenter = ({ url, title, w, h }) => {
  const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
  const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;

  const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
  const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;

  const systemZoom = width / window.screen.availWidth;
  const left = (width - w) / 2 / systemZoom + dualScreenLeft;
  const top = (height - h) / 2 / systemZoom + dualScreenTop;
  const newWindow = window.open(
    "/" + url,
    title,
    `
    scrollbars=yes,
    width=${w / systemZoom}, 
    height=${h / systemZoom}, 
    top=${top}, 
    left=${left}
    `
  );

  if (window.focus) newWindow.focus();
};

const windowOpen = (url, key, name, w, h, params) => {
  url += "/" + encodeURIComponent(key);
  if (params) url += `?${params}`;

  popupCenter({ url, title: name, w, h, params });
};

const setFilterData = (payload, key) => {
  const route = router.currentRoute;
  let name = (route.name || route.path) + "_filter";

  if (key) name = key + "_" + name;

  if (payload) {
    vm.$browserStore.setLocal(name, payload);
  } else {
    vm.$browserStore.delLocal(name);
  }
};

const getFilterData = (key) => {
  const route = router.currentRoute;
  let name = (route.name || route.path) + "_filter";
  if (key) name = key + "_" + name;
  return vm.$browserStore.getLocal(name);
};

const getSortOrders = (options) => {
  const opts = { ...options };
  return array(opts.sortBy).map((x, index) => ({
    property: `"${upperCaseFirst(x)}"`,
    direction: opts.sortDesc[index] == true ? "desc" : "asc",
  }));
};

const getObjectParam = (key, items) => {
  const item = items.find((item) => item.name == key);
  return item.tryCount;
};

const uniqArray = (_array, keys) => {
  if (array(keys)?.length) {
    return array(_array).filter((value, index, self) => index == self.findIndex((t) => keys.filter((key) => value[key] && value[key] == t[key]).length));
  }

  return _array;
};

Vue.prototype.$uuid = uuid;
Vue.prototype.$getSortOrders = getSortOrders;
Vue.prototype.$array = array;
Vue.prototype.$toString = toString;
Vue.prototype.$uniqArray = uniqArray;
Vue.prototype.$firstArrayItem = firstArrayItem;
Vue.prototype.$lastArrayItem = lastArrayItem;
Vue.prototype.$compareArray = compareArray;
Vue.prototype.$setInputFocus = setInputFocus;
Vue.prototype.$number = number;
Vue.prototype.$parseNumbers = parseNumbers;
Vue.prototype.$getSort = getSort;
Vue.prototype.$copyJson = copyJson;
Vue.prototype.$hasProp = hasProp;
Vue.prototype.$windowOpen = windowOpen;
Vue.prototype.$setFilterData = setFilterData;
Vue.prototype.$getFilterData = getFilterData;
Vue.prototype.$getObjectParam = getObjectParam;
