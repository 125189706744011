<script>
import tnVue from "@/mixins/tn.vue";

export default {
  mixins: [tnVue],

  data: () => ({
    dialog: true,
    ln: "how_to_use_from_selection",
  }),

  watch: {
    dialog(v) {
      if (!v) setTimeout(() => this.$emit("close"), 100);
    },
  },

  methods: {
    onClose() {
      this.dialog = false;
      this.$emit("close");
    },

    onOpenTelegram() {
      window.open("https://t.me/osonprice_uz", "_blank");
      this.$emit("join");
    },
  },
};
</script>

<template>
  <v-dialog
    v-model="dialog"
    max-width="500"
    content-class="custom-dialog"
    persistent
  >
    <div
      @click="dialog = false"
      v-ripple
      class="btn-close"
    >
      <v-icon color="#676B92">mdi-close</v-icon>
    </div>

    <div class="_modal-content">
      <h2 class="_title"> {{ tn("title") }} </h2>
      <p class="_description">{{ tn("description") }}</p>

      <v-btn
        width="50%"
        color="error"
        class="mt-5"
        depressed
        rounded
        @click="onOpenTelegram"
      >
        {{ tn("join_btn") }}
      </v-btn>

      <div
        class="mt-2 fs-14 primary--text cursor-pointer"
        @click="onClose"
      >
        {{ tn("continue_btn") }}
      </div>
    </div>
  </v-dialog>
</template>

<style lang="scss" scoped>
::v-deep(.custom-dialog) {
  position: relative;
  border-radius: 30px;

  .btn-close {
    position: absolute;
    top: 16px;
    right: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 40px;
    background-color: white;
    border-radius: 8px;
    cursor: pointer;
  }
}

._modal-content {
  text-align: center;
  padding: 30px;
  border-radius: 30px;
  background: white;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);

  ._title {
    font-size: 22px;
    font-weight: bold;
    color: #1f2937;
    max-width: 98%;
    margin-bottom: 16px;
  }

  ._description {
    font-size: 18px;
    color: #374151;
  }
}
</style>
